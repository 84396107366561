import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

import {
	setModoTela, initForm, getListaChecklistResposta, setChecklist, baixarImagem, gerarPdf,
	getListaAbastecida,	getListaDespesaViagem, gerarPdfChecklist, getListaMulta, salvarMulta
} from './viagemLancamentoActions';

import {
	setModoTela as setModoTelaViagem, initForm as initFormViagem, salvar as salvarViagem, excluir as excluirViagem,
	getLista as getListaViagem,
	getListaEstado,
	getListaCidade,
	getListaViagemStatus,
	getListaViagemLancamento,
	getListaLocal,
	getListaVeiculo,
	excluirVeiculo,
	getListaMotorista,
	getListaTransportadora,
	getListaProduto,
	getListaUnidadeMedida,
	getListaProximaCarga,
	getListaPosto,
	getListaRota
} from '../viagem/viagemActions';

import { getLista as getListaVale } from '../vale/valeActions';
import { getLista as getListaDevolucao } from '../devolucao/devolucaoActions';

class ViagemLancamentoForm extends Component {

	state = {

	}

	componentWillMount() {
		this.props.getListaChecklistResposta(this.props.formularioValues.id);
		this.props.setChecklist(null);
		this.props.getListaViagem();
		this.props.getListaEstado();
		this.props.getListaCidade();
		this.props.getListaViagemStatus();
		this.props.getListaViagemLancamento();
		this.props.getListaLocal();
		this.props.getListaVeiculo();
		this.props.getListaMotorista();
		this.props.getListaTransportadora();
		this.props.getListaProduto();
		this.props.getListaUnidadeMedida();
		this.props.getListaProximaCarga();
		this.props.getListaPosto();
		this.props.getListaRota();

		this.props.getListaAbastecida(this.props.formularioValues.id_motorista, this.props.formularioValues.datahora_inicio, this.props.formularioValues.datahora_fim);
		this.props.getListaDespesaViagem(this.props.formularioValues.id_motorista, this.props.formularioValues.datahora_inicio, this.props.formularioValues.datahora_fim);
		this.props.getListaVale();
		this.props.getListaDevolucao();
		this.props.getListaMulta(this.props.formularioValues.id 
			? { id_viagem_lancamento: this.props.formularioValues.id, id_motorista: this.props.formularioValues.id_motorista}
			: null
		);
	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		// 1- Carregamento, 2- Descarga, 3- Início de Viagem, 4- Fim de Viagem, 5- Periódico
		let checklistRespostaInicio = (this.props.listaChecklistResposta || []).filter(checklist => checklist.id_checklist_tipo == 3)[0];
		let checklistRespostaFim = (this.props.listaChecklistResposta || []).filter(checklist => checklist.id_checklist_tipo == 4)[0];

		let totalAbastecidaEmpresa = 0;
		let totalAbastecidaMotorista = 0;
		let totalDespesaEmpresa = 0;
		let totalDespesaMotorista = 0;
		let totalVale = 0;
		let totalDevolucao = 0;

		let tipoVeiculo = this.props.listaVeiculoTipo.filter(item => item.id == this.props.formularioValues.id_veiculo_tipo)[0];
		let possuiCarreta1 = tipoVeiculo ? tipoVeiculo.carreta1 : false;
		let possuiCarreta2 = tipoVeiculo ? tipoVeiculo.carreta2 : false;

		let maosDevolvido = 0;
		(this.props.listaDevolucao || []).filter(item =>
			item.id_motorista == this.props.formularioValues.id_motorista
			&& DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_devolucao)) >= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_inicio)
			&& (!this.props.formularioValues.datahora_fim || DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_devolucao)) <= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_fim))
		).forEach(item => {
			maosDevolvido += parseFloat(item.valor);
		});

		let resultadoComissao = 0;
		let detalhamentoComissao = 0;
		
		let descontarFretesRecebidos = 0;
		let resultadoDiaria = 0;
		let detalhamentoDiaria = 0;
		let descontarMotorista = 0;
		let descontarMultas = 0;
		let descontarDebitosEmpresa = 0;
		let resultadoMultasEmpresa = 0;

		this.props.listaMulta.map(item => {
			if (item.id_viagem_lancamento) {
				//Motorista
				if (item.id_causador == 2) {
					descontarMultas += parseFloat(item.valor || 0);
				} else {
					resultadoMultasEmpresa += parseFloat(item.valor || 0);
				}
			}
		});

		let kmFretes = 0;
		let kmFimFrete = 0;

		let meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
		let listaGraficoResultado = [];

		(this.props.registro.fretes || []).filter(item => item && item.id_status > 1).forEach(item => {
			resultadoComissao += parseFloat(item.comissao_motorista || 0);
			detalhamentoComissao += parseFloat(item.comissao_motorista || 0);
			//Recebido pelo Motorista
			if (item.id_tipo_saldo == 1) {
				descontarFretesRecebidos += (parseFloat(item.valor_saldo || 0))				
			}
			//Recebido pelo Motorista
			if (item.id_tipo_adiantamento == 1) {
				descontarFretesRecebidos += (parseFloat(item.valor_adiantamento || 0))				
			}
			resultadoDiaria += parseFloat(item.diaria_empresa || 0);
			detalhamentoDiaria += parseFloat(item.diaria_motorista || 0);
			//Recebido pelo motorista
			if (item.id_diaria_tipo == 1) {
				descontarMotorista += parseFloat(item.diaria_empresa || 0) + parseFloat(item.diaria_motorista || 0);
			}
			
			if (item.km_final && item.km_inicial) {
				kmFretes += parseInt(item.km_final) - parseInt(item.km_inicial);
			}
			if (item.km_final || item.km_inicial) {
				kmFimFrete = parseInt(item.km_final || item.km_inicial);
			}

			//Gráfico
			let mes = meses[new Date(item.datahora_carga).getMonth()];
			if (!listaGraficoResultado.filter(grafico => grafico.mes == mes).length) {
				listaGraficoResultado.push({
					mes: mes,
					faturamento: parseFloat(item.diaria_empresa || 0) + parseFloat(item.valor_total || 0),
					custo: parseFloat(item.comissao_motorista || 0),
					resultado: parseFloat(item.diaria_empresa || 0) + parseFloat(item.valor_total || 0) - parseFloat(item.comissao_motorista || 0)
				}); 
			} else {
				listaGraficoResultado = listaGraficoResultado.map(grafico => {
					if (grafico.mes == mes) {
						return {
							...grafico,
							faturamento: grafico.faturamento + parseFloat(item.diaria_empresa || 0) + parseFloat(item.valor_total || 0),
							custo: grafico.custo +  parseFloat(item.comissao_motorista || 0),
							resultado: grafico.resultado + parseFloat(item.diaria_empresa || 0) + parseFloat(item.valor_total || 0) - parseFloat(item.comissao_motorista || 0)
						};
					} else {
						return grafico;
					}
				})
			}
			
		});

		let resultadoFaturamento = parseFloat(this.props.formularioValues.total_faturamento || 0);	
		let resultadoDespesaGeral = parseFloat(this.props.formularioValues.total_despesas || 0);
		let resultadoCombustivelGeral = parseFloat(this.props.formularioValues.total_abastecidas || 0);	

		let resultadoTotal = resultadoFaturamento - resultadoDiaria - detalhamentoComissao - resultadoDespesaGeral - resultadoCombustivelGeral - resultadoMultasEmpresa;

		let precoMedioDiesel = 0;
		let abastecidas = 0;
		let quantidadeDiesel = 0;

		let detalhamentoCombustivelMotorista = 0;

		this.props.listaAbastecida.filter(item =>
			item.id_motorista == this.props.formularioValues.id_motorista
			&& DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida)) >= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_inicio)
			&& (!this.props.formularioValues.datahora_fim || DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida)) <= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_fim))
		).forEach(item => {

			detalhamentoCombustivelMotorista += (item.nome_forma_pagamento || '').includes('MOTORISTA') ? parseFloat(item.valor) : 0;

			//DIESEL
			if (item.id_produto == 1) {
				abastecidas++;
				precoMedioDiesel += parseFloat(item.preco);
				quantidadeDiesel += parseFloat(item.quantidade);
			}

			let mes = meses[new Date(item.datahora_abastecida).getMonth()];
			if (!listaGraficoResultado.filter(grafico => grafico.mes == mes).length) {
				listaGraficoResultado.push({
					mes: mes,
					custo: parseFloat(item.valor || 0),
					resultado: parseFloat(item.diaria_empresa || 0) + parseFloat(item.valor_total || 0) - parseFloat(item.comissao_motorista || 0)
				}); 
			} else {
				listaGraficoResultado = listaGraficoResultado.map(grafico => {
					if (grafico.mes == mes) {
						return {
							...grafico,
							custo: grafico.custo +  parseFloat(item.valor || 0),
							resultado: grafico.resultado - parseFloat(item.valor || 0)
						};
					} else {
						return grafico;
					}
				})
			}
		});

		precoMedioDiesel = abastecidas ? precoMedioDiesel / abastecidas : 0;

		let detalhamentoDespesaMotorista = 0;

		this.props.listaDespesaViagem.filter(item =>
			item.id_motorista == this.props.formularioValues.id_motorista
			&& DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa)) >= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_inicio)
			&& (!this.props.formularioValues.datahora_fim || DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa)) <= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_fim))
		).forEach(item => {

			detalhamentoDespesaMotorista += item.pagamento_motorista ? parseFloat(item.valor) : 0;

			let mes = meses[new Date(item.datahora_despesa).getMonth()];
			if (!listaGraficoResultado.filter(grafico => grafico.mes == mes).length) {
				listaGraficoResultado.push({
					mes: mes,
					custo: parseFloat(item.valor || 0),
					resultado: parseFloat(item.diaria_empresa || 0) + parseFloat(item.valor_total || 0) - parseFloat(item.comissao_motorista || 0)
				}); 
			} else {
				listaGraficoResultado = listaGraficoResultado.map(grafico => {
					if (grafico.mes == mes) {
						return {
							...grafico,
							custo: grafico.custo +  parseFloat(item.valor || 0),
							resultado: grafico.resultado - parseFloat(item.valor || 0)
						};
					} else {
						return grafico;
					}
				})
			}
		});

		let detalhamentoDevolvido = maosDevolvido;

		let detalhamentoTotal = detalhamentoComissao + detalhamentoDiaria + detalhamentoCombustivelMotorista + detalhamentoDespesaMotorista;

		let descontarValesRecebidos = parseFloat(this.props.formularioValues.total_vale || 0);
		let descontarTotal = descontarValesRecebidos + descontarFretesRecebidos + descontarMotorista;

		let maosCombustivelMotorista = detalhamentoCombustivelMotorista;
		let maosDespesaMotorista = detalhamentoDespesaMotorista;
		let maosDescontar = descontarTotal;
		let maosValePessoal = 0;

		this.props.listaVale.filter(item =>
			item.id_motorista == this.props.formularioValues.id_motorista
			&& DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale)) >= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_inicio)
			&& (!this.props.formularioValues.datahora_fim || DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale)) <= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_fim))
		).forEach(item => {
			if (item.pessoal) {
				maosValePessoal += parseFloat(item.valor);
			}
		});
						
		let maosTotal = maosDescontar - maosCombustivelMotorista - maosDespesaMotorista - maosDevolvido - maosValePessoal;

		let acertoMotorista = detalhamentoTotal;
		let acertoDescontar = descontarTotal;
		let acertoDevolvido = maosDevolvido;
		let acertoTotal = detalhamentoTotal + acertoDevolvido - descontarTotal - descontarMultas - descontarDebitosEmpresa;

		let kmInicial = 0;
		if (checklistRespostaInicio) {
			let pergunta = (JSON.parse(checklistRespostaInicio.respostas) || []).filter(resposta => resposta.pergunta == 'KM SAÍDA')[0];
			kmInicial = pergunta ? parseInt((pergunta.observacao || '').replace(/\D/g, "")) : 0;
		}
		let kmFinal = 0;
		if (checklistRespostaFim) {
			let pergunta = (JSON.parse(checklistRespostaFim.respostas) || []).filter(resposta => resposta.pergunta == 'KM CHEGADA')[0];
			kmFinal = pergunta ? parseInt((pergunta.observacao || '').replace(/\D/g, "")) : 0;
		}
		
		let kmPercorridoTotal = 0;
		if (kmInicial && kmFinal) {
			kmPercorridoTotal = kmFinal - kmInicial;
		} else if (kmInicial) {
			kmPercorridoTotal = kmFimFrete - kmInicial;
		}

		let listaGraficoLinha = [];
		listaGraficoResultado.forEach((item, i) => {
			let faturamento = 0;
			let custo = 0;
			let resultado = 0;
		
			listaGraficoResultado.forEach((itemAux, j) => {
		
				if (i >= j) {
					faturamento += itemAux.faturamento;
					custo += itemAux.custo;
					resultado += itemAux.resultado;
				}
			});
			listaGraficoLinha.push({
				mes: item.mes,
				faturamento: faturamento,
				custo: custo,
				resultado: resultado
			});
		});

		let detalhamentoViagemMedia = kmPercorridoTotal ? quantidadeDiesel / kmPercorridoTotal : 0;
		let detalhamentoViagemKmPercorido = kmPercorridoTotal;
		let detalhamentoViagemKmVazio = kmPercorridoTotal - kmFretes;
		let detalhamentoViagemKmCarregado = kmFretes;
		let detalhamentoViagemDiasViagem = parseInt(this.props.formularioValues.dias_viagem || 0);
		let detalhamentoViagemReceita = detalhamentoViagemDiasViagem ? resultadoFaturamento / detalhamentoViagemDiasViagem : 0;
		let detalhamentoViagemValorMedioDiesel = precoMedioDiesel;
		let detalhamentoViagemTotalFretes = (this.props.registro.fretes || []).filter(item => item).length;//resultadoTotal;

		let graficoFaturamentoDiaria = resultadoFaturamento + resultadoDiaria;
		let graficoCustoViagem = detalhamentoComissao + resultadoDespesaGeral + resultadoCombustivelGeral;
		let graficoResultado = resultadoTotal;

		let graficoTotal = graficoFaturamentoDiaria + graficoCustoViagem;
		graficoFaturamentoDiaria = parseInt((graficoFaturamentoDiaria * 100) / graficoTotal);
		graficoCustoViagem = parseInt((graficoCustoViagem * 100) / graficoTotal);


		return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
	                    	<Row>
								<Field
									name='numero_viagem'
									component={LabelAndInput}
									label='Número Viagem' placeholder='Informe o número (Atua)'
									cols='12 12 3 3'
									readOnly={readOnly} />

	                            <Field
	                                name='datahora_inicio'
	                                component={LabelAndInputDateTime}
	                                label='Data/Hora Início' placeholder='Informe a data'
	                                cols='12 12 3 3'
	                                readOnly={readOnly} />

	                            <Field
	                                name='datahora_fim'
	                                component={LabelAndInputDateTime}
	                                label='Data/Hora Fim' placeholder='Informe a data'
	                                cols='12 12 3 3'
	                                readOnly={readOnly} />
									
								{this.props.formularioValues.id && 
								<LabelAndInput
	                                label='Tipo de Viagem' placeholder='Informe o tipo'
	                                cols='12 12 3 3'
	                                readOnly={'readOnly'}
									value={this.props.formularioValues.veiculo_terceiro ? 'Terceiro' : 'Frota Própria'}
									onChange={() => {}} />}

	                        </Row>
	                        <Row>
	                            <Field
	                                name='id_motorista'
	                                component={Select}
	                                label='Motorista' placeholder='Informe o motorista'
	                                cols='12 12 12 12'
	                                readOnly={readOnly}
	                                options={this.props.listaMotorista}/>

	                            <Field
	                                name='id_veiculo'
	                                component={Select}
	                                label='Veículo' placeholder='Informe o veículo'
	                                cols='12 12 3 3'
	                                readOnly={readOnly}
	                                options={this.props.listaVeiculo.filter(item => !item.carreta)}/>

								<Field
									name='id_veiculo_tipo'
									component={Select}
	                                label='Tipo do Veículo' placeholder='Informe o tipo do veículo '
	                                cols='12 12 3 3'
									readOnly={readOnly}
	                                options={this.props.listaVeiculoTipo}/>

								{possuiCarreta1 ? (
									<Field
										name='id_carreta1'
										component={Select}
										label='Carreta 1' placeholder='Informe a carreta'
										cols='12 12 3 3'
										readOnly={readOnly}
										options={this.props.listaVeiculo.filter(item => item.carreta)}/>
								) : null}

								{possuiCarreta2 ? (
									<Field
										name='id_carreta2'
										component={Select}
										label='Carreta 2' placeholder='Informe a carreta'
										cols='12 12 3 3'
										readOnly={readOnly}
										options={this.props.listaVeiculo.filter(item => item.carreta)}/>
								) : null}
							</Row>
							<Row alignCenter style={{ marginTop: 20, marginBottom: 20 }}>
								<Grid cols='6 6 4 3'>
									<Button
										text='Checklist Inicial'
										type={'primary'}
										icon={'fa fa-tasks'}
										disabled={!checklistRespostaInicio}
										event={() => this.props.setChecklist(checklistRespostaInicio)} />
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Checklist Final'
										type={'primary'}
										icon={'fa fa-tasks'}
										disabled={!checklistRespostaFim}
										event={() => this.props.setChecklist(checklistRespostaFim)} />
								</Grid>

	                        </Row>
							{this.props.registro.id ? (
								<Table responsive striped style={{ maxHeight: '100%' }}>
									<THead>
										<Tr>
											<Th>Motorista</Th>
											<Th alignCenter>Placa</Th>
											<Th alignCenter>Origem Carga</Th>
											<Th alignCenter>Data Carga</Th>
											<Th alignCenter>Checklist Coleta</Th>
											<Th alignCenter>Destino Carga</Th>
											<Th alignCenter>Data Descarga</Th>
											<Th alignCenter>Checklist Descarga</Th>
											<Th alignRight>Faturamento</Th>
											<Th alignRight>Comissão</Th>
											<Th alignRight>Despesa</Th>
											<Th alignRight>Resultado</Th>
											<Th alignRight>Relatório Checklist</Th>
										</Tr>
									</THead>
									<TBody>
										{(this.props.registro.fretes || []).filter(item => item).map((item, i) => {

											// 1- Carregamento, 2- Descarga, 3- Início de Viagem, 4- Fim de Viagem, 5- Periódico
											let checklistRespostaCargas = [];
											(this.props.listaChecklistResposta || []).filter(checklist => checklist.id_viagem == item.id && checklist.id_checklist_tipo == 1).forEach(check => {
												if (checklistRespostaCargas.filter(checkItem => checkItem.datahora_checklist == check.datahora_checklist).length == 0) {
													checklistRespostaCargas.push(check);
												}
											});

											let checklistRespostaDescargas = [];
											(this.props.listaChecklistResposta || []).filter(checklist => checklist.id_viagem == item.id && checklist.id_checklist_tipo == 2).forEach(check => {
												if (checklistRespostaDescargas.filter(checkItem => checkItem.datahora_checklist == check.datahora_checklist).length == 0) {
													checklistRespostaDescargas.push(check);
												}
											});


											let custoCombustivelDesloca = !item.deslocamento_qtde_km ? 0 : (item.deslocamento_qtde_km / item.deslocamento_media) * item.deslocamento_preco_combustivel;
											let custoTotalDeslocamento = item.deslocamento_pedagio + custoCombustivelDesloca;
											let custoCombustivelCarregado = item.qtde_km && item.media && item.preco_combustivel ? (item.qtde_km / item.media) * item.preco_combustivel : 0;
											let custoTotalFrete = item.comissao_motorista + custoCombustivelCarregado + item.seguro_carga 
												+ item.outros_custos + item.outros_custos_2 + item.outros_custos_3 + item.outros_custos_4 + item.outros_custos_5 
												+ item.carregado_pedagio;
											let lucroLiquido = item.valor_total - custoTotalFrete - custoTotalDeslocamento;

											return (
												<Tr key={item.id}
													onClick={() => this.setState({ ...this.state, linhaSelecionada: i })}
													onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: i })} >
													<Td>{item.nome_motorista}</Td>
													<Td alignCenter>{item.placa_veiculo}</Td>
													<Td alignCenter>{item.nome_cidade_carga} - {item.sigla_estado_carga}</Td>
													<Td alignCenter>{item.datahora_carga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga, false) : ''}</Td>
													<Td alignCenter>
														{checklistRespostaCargas.map(checklistRespostaCarga => (
															<ButtonTable
																type={'primary'}
																icon={'fas fa-tasks'}
																disabled={!checklistRespostaCarga}
																visible={true}
																event={() => {
																	this.props.setChecklist(checklistRespostaCarga);
																}} />
														))}
														{checklistRespostaCargas.length == 0 ? (
															<ButtonTable
																type={'primary'}
																icon={'fas fa-tasks'}
																disabled={true}
																visible={true}
																event={() => {

																}} />
														) : null}
													</Td>
													<Td alignCenter>{item.nome_cidade_descarga} - {item.sigla_estado_descarga}</Td>
													<Td alignCenter>{item.datahora_descarga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga, false) : ''}</Td>
													<Td alignCenter>
														{checklistRespostaDescargas.map(checklistRespostaDescarga => (
															<ButtonTable
																type={'primary'}
																icon={'fas fa-tasks'}
																disabled={!checklistRespostaDescarga}
																visible={true}
																event={() => {
																	this.props.setChecklist(checklistRespostaDescarga);
																}} />
														))}
														{checklistRespostaDescargas.length == 0 ? (
															<ButtonTable
																type={'primary'}
																icon={'fas fa-tasks'}
																disabled={true}
																visible={true}
																event={() => {

																}} />
														) : null}
														<ButtonTable
															type={'success'}
															icon={'fas fa-truck'}
															visible={true}
															event={() => {

																let registro = {
																	...item,
																	datahora_agendamento: item.datahora_agendamento ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_agendamento) : null,
																	datahora_carga: item.datahora_carga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga) : null,
																	datahora_descarga: item.datahora_descarga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga) : null,
																	quantidade: String(item.quantidade || 0).replace(".", ","),
																	preco_unitario: String(item.preco_unitario || 0).replace(".", ","),
																	valor_tarifa: String(item.valor_tarifa || 0).replace(".", ","),
																	percentual_adiantamento: String(item.percentual_adiantamento || 0).replace(".", ","),
																	percentual_saldo: String(item.percentual_saldo || 0).replace(".", ","),
																	valor_adiantamento: String(item.valor_adiantamento || 0).replace(".", ","),
																	valor_saldo: String(item.valor_saldo || 0).replace(".", ","),
																	valor_pedagio: String(item.valor_pedagio || 0).replace(".", ","),
																	valor_descarga: String(item.valor_descarga || 0).replace(".", ","),
																	porcentagem_comissao_moto: String(item.porcentagem_comissao_moto || 0).replace(".", ","),
																	qtde_km: String(item.qtde_km || 0).replace(".", ","),
																	media: String(item.media || 0).replace(".", ","),
																	preco_combustivel: String(item.preco_combustivel || 0).replace(".", ","),
																	seguro_carga: String(item.seguro_carga || 0).replace(".", ","),
																	outros_custos: String(item.outros_custos || 0).replace(".", ","),
																	deslocamento_origem_destino: item.deslocamento_origem_destino,
																	deslocamento_qtde_km: String(item.deslocamento_qtde_km || 0).replace(".", ","),
																	deslocamento_media: String(item.deslocamento_media || 0).replace(".", ","),
																	deslocamento_preco_combustivel: String(item.deslocamento_preco_combustivel || 0).replace(".", ","),
																	deslocamento_pedagio: String(item.deslocamento_pedagio || 0).replace(".", ","),
																	carregado_pedagio: String(item.carregado_pedagio || 0).replace(".", ","),
																	valor_tarifa_motorista: String(item.valor_tarifa_motorista || 0).replace(".", ","),
																	diaria_empresa: String(item.diaria_empresa || 0).replace(".", ","),
																	diaria_motorista: String(item.diaria_motorista || 0).replace(".", ","),
																	diaria_total: (parseFloat(item.diaria_empresa || 0) + parseFloat(item.diaria_motorista || 0)).toFixed(2).replace(".", ","),
																	outros_custos_2: String(item.outros_custos_2 || 0).replace(".", ","),
																	diaria_total_2: String(item.diaria_total_2 || 0).replace(".", ","),
																	outros_custos_3: String(item.outros_custos_3 || 0).replace(".", ","),
																	diaria_total_3: String(item.diaria_total_3 || 0).replace(".", ","),
																	outros_custos_4: String(item.outros_custos_4 || 0).replace(".", ","),
																	diaria_total_4: String(item.diaria_total_4 || 0).replace(".", ","),
																	outros_custos_5: String(item.outros_custos_5 || 0).replace(".", ","),
																	diaria_total_5: String(item.diaria_total_5 || 0).replace(".", ",")
																}

																this.props.setModoTela('viagem', this.props.registro);
																this.props.setModoTelaViagem('cadastro', registro);
																this.props.initFormViagem(registro)
															}} />
													</Td>
													<Td alignRight>R$ {FormatUtils.formatarValorTelaDecimal(item.id_status <= 1 ? 0 : item.valor_total, 2)}</Td>
													<Td alignRight>R$ {FormatUtils.formatarValorTelaDecimal(item.id_status <= 1 ? 0 : item.comissao_motorista, 2)}</Td>
													<Td alignRight>R$ {FormatUtils.formatarValorTelaDecimal(item.id_status <= 1 ? 0 : custoTotalFrete, 2)}</Td>
													<Td alignRight>R$ {FormatUtils.formatarValorTelaDecimal(item.id_status <= 1 ? 0 : lucroLiquido, 2)}</Td>


													<Td alignCenter>
														<ButtonTable
															type={'primary'}
															icon={'fas fa-tasks'}
															disabled={!(checklistRespostaCargas.length + checklistRespostaDescargas.length)}
															visible={true}
															event={() => {
																this.props.gerarPdfChecklist(this.props.formularioValues, {
																		...item,
																		datahora_carga: item.datahora_carga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga) : null,
																		datahora_descarga: item.datahora_descarga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga) : null
																	}, {
																	cargas: checklistRespostaCargas || [],
																	descargas: checklistRespostaDescargas || []
																})
															}} />
													</Td>
												</Tr>
											)
										})}
									</TBody>
								</Table>
							) : null}
							<Row>

							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 4 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
										<Button
											text='Salvar'
											submit
											type={'success'}
											icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 4 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
								<Grid cols='6 4 4 3'>
									<Button
										text='Imprimir Relatório Final de Viagem'
										type={'primary'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.gerarPdf(this.props.formularioValues)} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>

				{this.props.checklistResposta ? (
					<ContentCard>
						<Form event={this.props.handleSubmit}>
							<ContentCardBody>

								<Table responsive striped style={{ maxHeight: '100%' }}>
									<THead>
										<Tr>
											<Th>Pergunta</Th>
											<Th alignCenter>Conformidade</Th>
											<Th>Observação</Th>
											<Th alignCenter>Foto</Th>
										</Tr>
									</THead>
									<TBody>
										{(JSON.parse(this.props.checklistResposta.respostas) || []).map((item, i) => {
											return (
												<Tr key={item.id}
													onClick={() => this.setState({ ...this.state, linhaSelecionada: i })}
													onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: i })} >
													<Td>{item.pergunta}</Td>
													<Td alignCenter>{item.conformidade == null ? '' : (item.conformidade ? 'SIM' : 'NÃO')}</Td>
													<Td>{item.observacao}</Td>
													<Td alignCenter>
														<div style={{ display: 'flex', flexDirection: 'column' }}>
															{item.imagem1 ? (
																<span class="">
																	<img src={item.imagem1} style={{ height: 400 }} />
																</span>
															) : null}
															{item.imagem2 ? (
																<span class="">
																	<img src={item.imagem2} style={{ height: 400 }} />
																</span>
															) : null}
															{item.imagem3 ? (
																<span class="">
																	<img src={item.imagem3} style={{ height: 400 }} />
																</span>
															) : null}
															{item.imagem4 ? (
																<span class="">
																	<img src={item.imagem4} style={{ height: 400 }} />
																</span>
															) : null}
															{item.imagem5 ? (
																<span class="">
																	<img src={item.imagem5} style={{ height: 400 }} />
																</span>
															) : null}
															{item.imagem1 || item.imagem2 || item.imagem3 || item.imagem4 || item.imagem5 ? (
																<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
																	<button
																		type='button'
																		class='btn btn-flat btn-outline-primary'
																		style={{ width: '100%' }}
																		onClick={() => {
																			this.props.baixarImagem(item.imagem1, 'foto_1');
																			this.props.baixarImagem(item.imagem2, 'foto_2');
																			this.props.baixarImagem(item.imagem3, 'foto_3');
																			this.props.baixarImagem(item.imagem4, 'foto_4');
																			this.props.baixarImagem(item.imagem5, 'foto_5');
																		}}>
																		<i class='fas fa-download'></i>
																	</button>
																</div>
															) : null}
														</div>
													</Td>
												</Tr>
											)
										})}
									</TBody>
								</Table>

							</ContentCardBody>
							<ContentCardFooter>
								<Row alignCenter>
									<Grid cols='6 6 4 3'>
										<Button
											text='Fechar'
											type={'warning'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.setChecklist(null)} />
									</Grid>
								</Row>
							</ContentCardFooter>
						</Form>
					</ContentCard>
				) : null}

				{this.props.formularioValues.id && this.props.formularioValues.datahora_inicio ? (
					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='6 6 4 2'>
									<label>Abastecidas</label>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Table responsive>
									<Tr>
										<Th>Data Hora</Th>
										<Th>Fornecedor</Th>
										<Th>Cidade</Th>
										<Th>Pagamento</Th>
										<Th alignCenter>Produto</Th>
										<Th alignRight>Preço Pago</Th>
										<Th alignRight>Preço Negociado</Th>
										<Th alignRight>Diferença</Th>
										<Th alignCenter>Negociação</Th>
										<Th alignCenter>Status</Th>
										<Th alignRight>Media</Th>
										<Th alignRight>Valor</Th>
										<Th alignCenter>KM</Th>
									</Tr>

								<TBody>
									{this.props.listaAbastecida.filter(item =>
										item.id_motorista == this.props.formularioValues.id_motorista
										&& DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida)) >= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_inicio)
										&& (!this.props.formularioValues.datahora_fim || DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida)) <= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_fim))
									).map(item => {

										totalAbastecidaEmpresa += !(item.nome_forma_pagamento || '').includes('MOTORISTA') ? parseFloat(item.valor) : 0;
										totalAbastecidaMotorista += (item.nome_forma_pagamento || '').includes('MOTORISTA') ? parseFloat(item.valor) : 0;

										return (
											<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
												onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
												onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
												<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida)}</Td>
												<Td>{item.nome_fornecedor}</Td>
												<Td>{item.nome_cidade}</Td>
												<Td>{item.nome_forma_pagamento}</Td>
												<Td alignCenter>{item.nome_abastecida_produto}</Td>
												<Td alignRight>{FormatUtils.formatarValorTela(item.preco, 3)}</Td>
												<Td alignRight>{FormatUtils.formatarValorTela(item.preco_negociado, 2)}</Td>
												<Td alignRight>{FormatUtils.formatarValorTela(item.diferenca, 2)}</Td>
												<Td alignCenter>{item.id_negociacao ? (item.dentro_prazo ? 'Dentro Prazo' : 'Vencida') : '-'}</Td>
												<Td alignCenter style={{ fontWeight: 'bold', color: item.id_status == 0 ? '#000' : '#fff', backgroundColor: ['#ff0', '#00c853', '#29b6f6', '#ff5252'][item.id_status] }}>{item.nome_status}</Td>
												<Td alignRight>{item.completou ? FormatUtils.formatarValorTela(item.media_odometro, 2) : ''}</Td>
												<Td alignRight>{FormatUtils.formatarValorTela(item.valor, 2)}</Td>
												<Td alignCenter>{item.odometro}</Td>
											</Tr>
										)
									})}
									<Tr>
										<Td colspan={12} alignRight bold>Total Empresa</Td>
										<Td alignRight bold>{FormatUtils.formatarValorTela(totalAbastecidaEmpresa, 2)}</Td>
									</Tr>
									<Tr>
										<Td colspan={12} alignRight bold>Total Motorista</Td>
										<Td alignRight bold>{FormatUtils.formatarValorTela(totalAbastecidaMotorista, 2)}</Td>
									</Tr>
									<Tr>
										<Td colspan={12} alignRight bold>Total Geral</Td>
										<Td alignRight bold>{FormatUtils.formatarValorTela(totalAbastecidaEmpresa + totalAbastecidaMotorista, 2)}</Td>
									</Tr>
								</TBody>
							</Table>
						</ContentCardBody>
					</ContentCard>
				) : null}

				{this.props.formularioValues.id && this.props.formularioValues.datahora_inicio ? (
					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='6 6 4 2'>
									<label>Despesas</label>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Table responsive>
									<Tr>
										<Th>Data Hora</Th>
										<Th>Fornecedor</Th>
										<Th>Pagamento</Th>
										<Th>Produto</Th>
										<Th>Aprovado Por</Th>
										<Th alignRight>Valor</Th>
									</Tr>

								<TBody>
									{this.props.listaDespesaViagem.filter(item =>
										item.id_motorista == this.props.formularioValues.id_motorista
										&& DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa)) >= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_inicio)
										&& (!this.props.formularioValues.datahora_fim || DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa)) <= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_fim))
									).map(item => {

										totalDespesaEmpresa += !item.pagamento_motorista ? parseFloat(item.valor) : 0;
										totalDespesaMotorista += item.pagamento_motorista ? parseFloat(item.valor) : 0;

										return (
											<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
												onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
												onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
												<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa)}</Td>
												<Td>{item.fornecedor}</Td>
												<Td>{item.pagamento_motorista ? 'Motorista' : 'Empresa'}</Td>
												<Td>{item.nome_despesa_produto}</Td>
												<Td>{item.nome_usuario_aprovacao}</Td>
												<Td alignRight>{FormatUtils.formatarValorTela(item.valor, 2)}</Td>
											</Tr>
										);
									})}
									<Tr>
										<Td colspan={5} alignRight bold>Total Empresa</Td>
										<Td alignRight bold>{FormatUtils.formatarValorTela(totalDespesaEmpresa, 2)}</Td>
									</Tr>
									<Tr>
										<Td colspan={5} alignRight bold>Total Motorista</Td>
										<Td alignRight bold>{FormatUtils.formatarValorTela(totalDespesaMotorista, 2)}</Td>
									</Tr>
									<Tr>
										<Td colspan={5} alignRight bold>Total Geral</Td>
										<Td alignRight bold>{FormatUtils.formatarValorTela(totalDespesaEmpresa + totalDespesaMotorista, 2)}</Td>
									</Tr>
								</TBody>
							</Table>
						</ContentCardBody>
					</ContentCard>
				) : null}

				{this.props.formularioValues.id && this.props.formularioValues.datahora_inicio ? (
					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='6 6 4 2'>
									<label>Vales</label>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Table responsive>
								<THead>
									<Tr>
										<Th>Data Hora</Th>
										<Th alignCenter>Tipo</Th>
										<Th>Forma Pagamento</Th>
										<Th>Observação</Th>
										<Th alignRight>Valor</Th>
									</Tr>
								</THead>
								<TBody>
								{this.props.listaVale.filter(item =>
									item.id_motorista == this.props.formularioValues.id_motorista
									&& DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale)) >= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_inicio)
									&& (!this.props.formularioValues.datahora_fim || DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale)) <= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_fim))
								).map(item => {

										totalVale += parseFloat(item.valor);

										return (
											<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
												onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
												onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
												<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_vale)}</Td>
												<Td alignCenter>{item.pessoal ? 'PESSOAL' : 'CAMINHÃO'}</Td>
												<Td>{item.nome_forma}</Td>
												<Td>{item.observacao}</Td>
												<Td alignRight>{FormatUtils.formatarValorTela(item.valor, 2)}</Td>
											</Tr>
										);
									})}
									<Tr>
										<Td colspan={4} alignRight bold>Total Geral</Td>
										<Td alignRight bold>{FormatUtils.formatarValorTela(totalVale, 2)}</Td>
									</Tr>
								</TBody>
							</Table>
						</ContentCardBody>
					</ContentCard>
				) : null}
				
				{this.props.formularioValues.id && this.props.formularioValues.datahora_inicio ? (
					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='6 6 4 2'>
									<label>Devolução</label>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Table responsive>
								<THead>
									<Tr>
										<Th >Data Hora</Th>
										<Th >Forma Devolução</Th>
										<Th >Observação</Th>
										<Th alignRight>Valor</Th>
									</Tr>
								</THead>
								<TBody>
								{(this.props.listaDevolucao || []).filter(item =>
									item.id_motorista == this.props.formularioValues.id_motorista
									&& DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_devolucao)) >= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_inicio)
									&& (!this.props.formularioValues.datahora_fim || DateFormat.formatarDataHoraTelaParaSql(DateFormat.formatarDataHoraSqlParaTela(item.datahora_devolucao)) <= DateFormat.formatarDataHoraTelaParaSql(this.props.formularioValues.datahora_fim))
								).map(item => {

										totalDevolucao += parseFloat(item.valor);

										return (
											<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
												onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
												onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
												<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_devolucao)}</Td>
												<Td>{item.nome_forma}</Td>
												<Td>{item.observacao}</Td>
												<Td alignRight>{FormatUtils.formatarValorTela(item.valor, 2)}</Td>
											</Tr>
										);
									})}
									<Tr>
										<Td colspan={3} alignRight bold>Total Geral</Td>
										<Td alignRight bold>{FormatUtils.formatarValorTela(totalDevolucao, 2)}</Td>
									</Tr>
								</TBody>
							</Table>
						</ContentCardBody>
					</ContentCard>
				) : null}

				{this.props.formularioValues.id && this.props.formularioValues.datahora_inicio ? (
					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='6 6 4 2'>
									<label>Multa</label>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Table responsive>
								<Tr>
									<Th>Data Infração</Th>
									<Th alignCenter>Data Vencimento</Th>
									<Th alignCenter>Veículo</Th>
									<Th alignCenter>Cidade / UF</Th>
									<Th alignCenter>Auto Infração</Th>
									<Th alignCenter>Causador</Th>
									<Th alignCenter>Forma Pagamento</Th>
									<Th alignCenter>Descrição</Th>
									<Th alignCenter>Observação</Th>
									<Th alignRight>Valor</Th>
									<Th alignCenter>Associar</Th>
								</Tr>

								<TBody>
									{this.props.listaMulta.map(item => (
										<Tr key={item.id} >
											<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_infracao)}</Td>
											<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_vencimento)}</Td>
											<Td alignCenter>{item.placa_veiculo}</Td>
											<Td alignCenter>{item.nome_cidade}{item.nome_cidade ? ' - ' : ''}{item.sigla_estado}</Td>
											<Td alignCenter>{item.auto_infracao}</Td>
											<Td alignCenter>{item.nome_causador}</Td>
											<Td alignCenter>{item.nome_forma_pagamento}</Td>
											<Td alignCenter>{item.descricao}</Td>
											<Td alignCenter>{item.observacao}</Td>
											<Td alignRight>R$ {FormatUtils.formatarValorTela(parseFloat(item.valor || 0) - parseFloat(item.valor_quitado || 0), 2)}</Td>
											<Td>
												<select
													className='form-control'
													value={item.id_viagem_lancamento ? 1 : 2}
													onChange={data => {
														this.props.salvarMulta({
															...item,
															id_viagem_lancamento_atualizar: this.props.formularioValues.id,
															id_viagem_lancamento: item.id_viagem_lancamento ? null : this.props.formularioValues.id
														});
													}}
													style={{ fontSize: 14 }} >
													<option value={1}>Sim</option>
													<option value={2}>Não</option>
												</select>
											</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</ContentCardBody>
					</ContentCard>
				) : null}

				{this.props.formularioValues.id ? (
					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='12 12 12 12'>
									<label>Fechamento de Viagem</label>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Row>
								<Grid cols='12 12 12 12'>
									<Table responsive>
										<Tr>
											<Th style={{ borderLeft: '0.1rem solid #dee2e6' }}>Resultado Viagem</Th>
											<Th alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} alignRight>Valor</Th>
											<Th>Detalhamento Motorista</Th>
											<Th alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} alignRight>Valor</Th>
											<Th>Valores a Descontar</Th>
											<Th alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} alignRight>Valor</Th>
											<Th>Valores em Mãos</Th>
											<Th alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} alignRight>Valor</Th>
											<Th>Acerto Motorista</Th>
											<Th alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} alignRight>Valor</Th>
										</Tr>
										<TBody>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>(+) Faturamento</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(resultadoFaturamento, 2)}</Td>
												<Td>(+) Comissão</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(detalhamentoComissao, 2)}</Td>
												<Td>(+) Vales Recebidos Motorista</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(descontarValesRecebidos, 2)}</Td>
												<Td>(+) Valores a Descontar</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(maosDescontar, 2)}</Td>
												<Td>(+) Detalhamento Motorista</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(acertoMotorista, 2)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>(+) Diária Empresa</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(resultadoDiaria, 2)}</Td>
												<Td>(+) Diária Motorista</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(detalhamentoDiaria, 2)}</Td>
												<Td>(+) Fretes Recebidos Motorista</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(descontarFretesRecebidos, 2)}</Td>
												<Td>(-) Combustível Motorista</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(maosCombustivelMotorista, 2)}</Td>
												<Td>(-) Valores a descontar</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(acertoDescontar, 2)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>(-) Comissão Motorista</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(resultadoComissao, 2)}</Td>
												<Td>(+) Despesas Motorista</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(detalhamentoDespesaMotorista, 2)}</Td>
												<Td>(+) Diárias Recebidas Motorista</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(descontarMotorista, 2)}</Td>
												<Td>(-) Despesas Motorista</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(maosDespesaMotorista, 2)}</Td>
												<Td>(+) Valor Devolvido</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(acertoDevolvido, 2)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>(-) Despesas Geral</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(resultadoDespesaGeral, 2)}</Td>
												<Td>(+) Combustível Motorista</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(detalhamentoCombustivelMotorista, 2)}</Td>
												<Td>Total</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(descontarTotal, 2)}</Td>
												<Td>(-) Valor Devolvido</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(maosDevolvido, 2)}</Td>
												<Td>(-) Multas Motorista</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(descontarMultas, 2)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>(-) Combustível Geral</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(resultadoCombustivelGeral, 2)}</Td>
												<Td>Total</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(detalhamentoTotal, 2)}</Td>
												<Td></Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} ></Td>
												<Td>(-) Vale Pessoal</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(maosValePessoal, 2)}</Td>
												<Td>(-) Débitos Empresa</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(descontarDebitosEmpresa, 2)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>(-) Multas Empresa</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(resultadoMultasEmpresa, 2)}</Td>
												<Td></Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} ></Td>
												<Td></Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} ></Td>
												<Td>Total</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(maosTotal, 2)}</Td>
												<Td>Total</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(acertoTotal, 2)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>Resultado</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} >R$ {FormatUtils.formatarValorTela(resultadoTotal, 2)}</Td>
												<Td></Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} ></Td>
												<Td></Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} ></Td>
												<Td></Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} ></Td>
												<Td></Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }} ></Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ }}></Td>
												<Td alignRight style={{}} ></Td>
												<Td></Td>
												<Td alignRight></Td>
												<Td></Td>
												<Td alignRight style={{ }} ></Td>
												<Td></Td>
												<Td alignRight></Td>
												<Td></Td>
												<Td alignRight style={{ }} ></Td>
											</Tr>
										</TBody>
									</Table>
								</Grid>
							</Row>
							
							<Row>
								<Grid cols='12 12 3 3'>
									<Table responsive>
										<Tr>
											<Th>Detalhamento Viagem</Th>
											<Th alignRight>Valor</Th>
										</Tr>
										<TBody>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>Média</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }}>{FormatUtils.formatarValorTela(detalhamentoViagemMedia, 4)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>KM Percorrido</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }}>{FormatUtils.formatarValorTela(detalhamentoViagemKmPercorido, 0)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>KM Vazio</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }}>{FormatUtils.formatarValorTela(detalhamentoViagemKmVazio, 0)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>KM Carregado</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }}>{FormatUtils.formatarValorTela(detalhamentoViagemKmCarregado, 0)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>Dias Viagem</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }}>{FormatUtils.formatarValorTela(detalhamentoViagemDiasViagem, 0)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>Receita Dia</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }}>R$ {FormatUtils.formatarValorTela(detalhamentoViagemReceita, 2)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>Valor Médio Diesel</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }}>R$ {FormatUtils.formatarValorTela(detalhamentoViagemValorMedioDiesel, 4)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{ borderLeft: '0.1rem solid #dee2e6' }}>Total Fretes</Td>
												<Td alignRight style={{ borderRight: '0.1rem solid #dee2e6' }}>{FormatUtils.formatarValorTela(detalhamentoViagemTotalFretes, 0)}</Td>
											</Tr>
											<Tr height={55}>
												<Td style={{}}></Td>
												<Td alignRight style={{ }}></Td>
											</Tr>
										</TBody>
									</Table>
								</Grid>
								<Grid cols='12 12 3 3'>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<img  src={`
											https://quickchart.io/chart?width=300&height=200&c={
												type:'pie',
												data: {
													labels: ['FATURAMENTO/DIÁRIA', 'CUSTOS'],
													datasets: [{
														data: [${graficoFaturamentoDiaria}, ${100 - graficoFaturamentoDiaria /*graficoCustoViagem*/}],
														backgroundColor: [
															'rgb(75, 123, 204)', 'rgb(255, 69, 0)'
														]
													}]
												},
												options: {
													plugins: {
														datalabels: {
															color: 'rgb(255, 255, 255)',
															font: {
																size: 10
															},
															formatter: (val, ctx) => {
																return ${"`${val}%`"}
															}
														}													
													},
													legend: {
														display: true,
														position: 'bottom',
														labels: {
															fontSize: 8
														}
													}
												}
											}`}/>
									</div>
								</Grid>

								<Grid cols='12 12 6 6'>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<img  src={`
											https://quickchart.io/chart?width=350&height=200&c={
												type:'line',
												
												data: {
													labels: ${JSON.stringify(listaGraficoLinha.map(item => item.mes))},
													datasets: [{
														label:'Faturamento/Diária',
														data: ${JSON.stringify(listaGraficoLinha.map(item => item.faturamento))},
														fill: false,
														borderColor: 'rgb(30, 144, 255)'
													}, {
														label: 'Custo Viagem',
														data: ${JSON.stringify(listaGraficoLinha.map(item => item.custo))},
														fill: false,
														borderColor: 'rgb(255, 69, 0)'
													}, {
														label: 'Resultado',
														data: ${JSON.stringify(listaGraficoLinha.map(item => item.resultado))},
														fill: false,
														borderColor: 'rgb(50, 205, 50)'
													}]
												},												
												options: {
													legend: {
														display: true,
														position: 'bottom',
														labels: {
															fontSize: 8
														}
													},
													scales: {
														yAxes: [{
															ticks: {
																fontSize: 8
															}
														}],
														xAxes: [{
															ticks: {
																fontSize: 8
															}
														}]
													}
												}
											}`}/>
									</div>
								</Grid>
							</Row>

						</ContentCardBody>
					</ContentCard>
				) : null}
			</div>
		)
	}

}

ViagemLancamentoForm = reduxForm({ form: 'viagemLancamentoForm', destroyOnUnmount: false })(ViagemLancamentoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('viagemLancamentoForm')(state),
	registro: state.viagemLancamento.registro,
	listaMotorista: state.viagemLancamento.listaMotorista,
	listaVeiculo: state.viagemLancamento.listaVeiculo,
	listaVeiculoTipo: state.viagemLancamento.listaVeiculoTipo,
	listaChecklistResposta: state.viagemLancamento.listaChecklistResposta,
	checklistResposta: state.viagemLancamento.checklistResposta,
	listaAbastecida: state.viagemLancamento.listaAbastecida,
	listaDespesaViagem: state.viagemLancamento.listaDespesaViagem,
	listaVale: state.vale.lista,
	listaDevolucao: state.devolucao.lista,
	listaMulta: state.viagemLancamento.listaMulta
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, getListaChecklistResposta, setChecklist, baixarImagem,
	setModoTelaViagem, initFormViagem, salvarViagem, excluirViagem, getListaViagem, getListaEstado,
	getListaCidade, getListaViagemStatus, getListaViagemLancamento, getListaLocal, getListaVeiculo, excluirVeiculo, getListaMotorista,
	getListaTransportadora, getListaProduto, getListaUnidadeMedida, getListaProximaCarga, getListaPosto, getListaRota,
	getListaAbastecida, getListaDespesaViagem, gerarPdfChecklist, getListaVale, getListaDevolucao, gerarPdf, getListaMulta, getListaMulta, salvarMulta
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViagemLancamentoForm);
