import React from 'react'
import { getPreciseDistance, getCenter } from "geolib";
import {
	GoogleMap,
	Marker,
	LoadScript,
	StandaloneSearchBox,
	DirectionsRenderer,
	useJsApiLoader
} from '@react-google-maps/api';
//https://github.com/fcbergmann/my-map/blob/main/src/pages/MapPage.tsx
import {
  geocodeByPlaceId
} from 'react-places-autocomplete';

import Row from '../layout/row';
import Grid from '../layout/grid';
import Button from '../button/button';
import { getDistance } from 'geolib';
import LabelAndInput from '../form/labelAndInput';
import axios from 'axios';
import { setError } from '../toast/toast';

const raio = 15000;

export default props => {

	const [directionsRef, setDirectionsRef] = React.useState(null);
	const [places, setPlaces] = React.useState([]);

	const containerStyle = {
		width: '100%',
		height: window.innerHeight * 0.80
	};

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyAK578qzVAohLyd3UEMQzUbDYExVITWloE'
	});

	let subdividirSteps = (step, maxDistance = 10000) => {
		const newSteps = [];
  // Decodifica o polyline para obter a rota real
  const path = window.google.maps.geometry.encoding.decodePath(step.polyline.points);

  // Se o path tiver menos de 2 pontos, não há o que subdividir
  if (path.length < 2) return [step];

  let accumulatedDistance = 0;
  let segmentStart = path[0];
  let segmentPoints = [segmentStart];

  // Percorre os pontos da rota
  for (let i = 1; i < path.length; i++) {
    const currentPoint = path[i];
    const distanceBetween = window.google.maps.geometry.spherical.computeDistanceBetween(path[i - 1], currentPoint);
    accumulatedDistance += distanceBetween;
    segmentPoints.push(currentPoint);

    // Se a distância acumulada ultrapassar o maxDistance, precisamos criar um sub-step
    if (accumulatedDistance >= maxDistance) {
      // Precisamos encontrar o ponto exato na linha entre path[i-1] e currentPoint 
      // onde a distância acumulada atinja exatamente maxDistance.
      const excess = accumulatedDistance - maxDistance;
      const segmentDistance = distanceBetween;
      // Fração para retroceder do currentPoint para encontrar o ponto exato
      const fraction = (segmentDistance - excess) / segmentDistance;
      const interpolatedPoint = window.google.maps.geometry.spherical.interpolate(path[i - 1], currentPoint, fraction);

      // Cria um sub-step com o segmento atual
      newSteps.push({
        distance: {
          value: maxDistance,
          text: `${(maxDistance / 1000).toFixed(2)} km`
        },
        start_location: segmentStart,
        end_location: interpolatedPoint,
        instructions: `Sub-step de rota original`
      });

      // Reinicia para o próximo segmento: o novo ponto inicial é o ponto interpolado
      segmentStart = interpolatedPoint;
      // Recalcula a distância acumulada como o "excesso" que não foi usado
      accumulatedDistance = excess;
      // Reinicia o array de pontos para o novo segmento (opcional, se precisar dos pontos do sub-step)
      segmentPoints = [interpolatedPoint];
    }
  }

  // Caso reste um segmento menor que maxDistance, adiciona-o como último sub-step
  if (accumulatedDistance > 0) {
    const lastPoint = path[path.length - 1];
    newSteps.push({
      distance: {
        value: accumulatedDistance,
        text: `${(accumulatedDistance / 1000).toFixed(2)} km`
      },
      start_location: segmentStart,
      end_location: lastPoint,
      instructions: `Sub-step final de rota original`
    });
  }

  return newSteps;
	}

   let calcularRota = async (itens) => {

	   // eslint-disable-next-line no-undef
	   const DirectionsService = new google.maps.DirectionsService();
	   let result = await DirectionsService.route({
		   origin: { lat: parseFloat(props.origem.latitude), lng: parseFloat(props.origem.longitude) },
		   destination:{ lat: parseFloat(props.destino.latitude), lng: parseFloat(props.destino.longitude) },
		   waypoints: itens ? itens.map(item => ({ location: { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }, stopover: true })) : (props.rota && props.rota.waypoints ? props.rota.waypoints : []).map(waypoint => ({ location: { lat: waypoint.latitude, lng: waypoint.longitude }, stopover: true })),
		   optimizeWaypoints: true,
		   travelMode: 'DRIVING',
	   })
	   if (result.status == 'OK') {

		   let waypoints = [];

		   for (var i = 1; i < result.geocoded_waypoints.length -1; i++) {
			   let waypoint = result.geocoded_waypoints[i];
			   await geocodeByPlaceId(waypoint.place_id).then(results => {
				   waypoints.push({ latitude: results[0].geometry.location.lat(), longitude: results[0].geometry.location.lng() });
			   }).catch(error => console.error(error));
		   }

		   let link = `https://www.google.com/maps/dir/?api=1&origin=${props.origem.latitude},${props.origem.longitude}&destination=${props.destino.latitude},${props.destino.longitude}&waypoints=${waypoints.map(waypoint => `${waypoint.latitude},${waypoint.longitude}`).join('|')}&travelmode=driving`;

		   let pontos = [];
		   let passosLatLong = [];

		   // if (props.rota && props.rota.passos_localizacao && !itens) {
			//    for (var i = 0; i < props.rota.passos_localizacao.length; i++) {
			// 	   let item = props.rota.passos_localizacao[i];
		   //
			// 	   for (var j = 0; j < props.pontos.length; j++) {
 			// 		  let ponto = props.pontos[j];
 			// 		  if (ponto.latitude && ponto.longitude) {
 			// 			  let distancia = getDistance({ latitude: item.latitude, longitude: item.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) })
		   //
 			// 			  if (distancia < 20000) {
 			// 				  if (!pontos.filter(item => item.descricao == ponto.descricao)[0]) {
 			// 					  pontos.push(ponto);
 			// 				  }
 			// 			  }
 			// 		  }
 			// 	  }
			//    }
		   // } else {

		   
		   for (var i = 0; i < result.routes[0].legs.length; i++) {
			   let leg = result.routes[0].legs[i];

			   for (var j = 0; j < leg.steps.length; j++) {
				   let item = leg.steps[j];

				   const pontoA = { latitude: item.start_location.lat(), longitude: item.start_location.lng() };
				   passosLatLong.push(pontoA);

				   let subSteps = [];
				   if (item.distance.value > 2000) {
					   subSteps = subdividirSteps(item, 2000);

					   for (let k = 0; k < subSteps.length; k++) {
						   const subStep = subSteps[k];

						   passosLatLong.push({ latitude: subStep.start_location.lat(), longitude: subStep.start_location.lng() });
					   }
				   }

				   const pontoB = { latitude: item.end_location.lat(), longitude: item.end_location.lng() };
				   passosLatLong.push(pontoB);


				   for (var k = 0; k < props.pontos.length; k++) {
					   let ponto = props.pontos[k];

					   if (ponto.latitude && ponto.longitude) {
						   let distanciaOrigem = getDistance({ latitude: item.start_location.lat(), longitude: item.start_location.lng() }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) });
						   let distanciaDestino = getDistance({ latitude: item.end_location.lat(), longitude: item.end_location.lng() }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) });

						   let dentroRaio = distanciaOrigem < raio || distanciaDestino < raio;

						   // console.log('------------------------------------------------');
						   // console.log(`${item.start_location.lat()}, ${item.start_location.lng()}`);


						   if (!dentroRaio) {

							   for (let l = 0; l < subSteps.length; l++) {
								   const subStep = subSteps[l];


								   // console.log(`${subStep.start_location.lat()}, ${subStep.start_location.lng()}`);

								   if (!dentroRaio) {
									   let distanciaCentro = getDistance({ latitude: subStep.start_location.lat(), longitude: subStep.start_location.lng() }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) });

									   dentroRaio = distanciaCentro < raio;
								   }
							   }

						   }

						   // console.log(`${item.end_location.lat()}, ${item.end_location.lng()}`);

						   if (dentroRaio) {
							   if (!pontos.filter(item => item.descricao == ponto.descricao)[0]) {
								   pontos.push(ponto);
							   }
						   }
					   }
				   }

				  /*
				  const distancia = getPreciseDistance({ lat: pontoA.latitude, lng: pontoA.longitude }, { lat: pontoB.latitude, lng: pontoB.longitude });
				  								  
				  passosLatLong.push(pontoA);
				  
				  let centro1 = null;
				  let centro2 = null;
				  let centro3 = null;
				  let centro4 = null;
				  let centro5 = null;
				  let centro6 = null;
				  let centro7 = null;
				  let centro8 = null;
				  let centro9 = null;
				  let centro10 = null;
				  let centro11 = null;
				  let centro12 = null;
				  let centro13 = null;
				  let centro14 = null;
				  let centro15 = null;
				  let centro16 = null;
				  let centro17 = null;
				  let centro18 = null;
				  let centro19 = null;
				  let centro20 = null;
				  let centro21 = null;
				  let centro22 = null;
				  let centro23 = null;
				  let centro24 = null;
				  let centro25 = null;
				  let centro26 = null;
				  let centro27 = null;
				  let centro28 = null;
				  let centro29 = null;
				  let centro30 = null;

				  if (distancia > 10000) {
					centro1 = getCenter([pontoA, pontoB]);
				  	passosLatLong.push(centro1);
				  }

				  if (distancia > 20000) {
					centro2 = getCenter([pontoA, centro1]);
					passosLatLong.push(centro2);
					centro3 = getCenter([centro1, pontoB]);
					passosLatLong.push(centro3);
				  }

				  if (distancia > 40000) {
					centro4 = getCenter([pontoA, centro2]);
					passosLatLong.push(centro4);
					centro5 = getCenter([centro2, centro1]);
					passosLatLong.push(centro5);

					centro6 = getCenter([centro1, centro3]);
					passosLatLong.push(centro6);
					centro7 = getCenter([centro3, pontoB]);
					passosLatLong.push(centro7);
				  }
				  
				  if (distancia > 100000) {
					centro8 = getCenter([pontoA, centro4]);
					passosLatLong.push(centro8);

					centro9 = getCenter([centro4, centro2]);
					passosLatLong.push(centro9);

					centro10 = getCenter([centro2, centro5]);
					passosLatLong.push(centro10);

					centro11 = getCenter([centro5, centro1]);
					passosLatLong.push(centro11);

					centro12 = getCenter([centro1, centro6]);
					passosLatLong.push(centro12);

					centro13 = getCenter([centro6, centro3]);
					passosLatLong.push(centro13);

					centro14 = getCenter([centro3, centro7]);
					passosLatLong.push(centro14);

					centro15 = getCenter([centro7, pontoB]);
					passosLatLong.push(centro15);
				  }

				  if (distancia > 200000) {

					centro16 = getCenter([pontoA, centro8]);
					passosLatLong.push(centro16);

					centro17 = getCenter([centro8, centro4]);
					passosLatLong.push(centro17);

					centro18 = getCenter([centro4, centro9]);
					passosLatLong.push(centro18);

					centro19 = getCenter([centro9, centro2]);
					passosLatLong.push(centro19);

					centro20 = getCenter([centro2, centro10]);
					passosLatLong.push(centro20);

					centro21 = getCenter([centro10, centro5]);
					passosLatLong.push(centro21);

					centro22 = getCenter([centro5, centro11]);
					passosLatLong.push(centro22);

					centro23 = getCenter([centro11, centro1]);
					passosLatLong.push(centro23);

					centro24 = getCenter([centro1, centro12]);
					passosLatLong.push(centro24);

					centro25 = getCenter([centro12, centro6]);
					passosLatLong.push(centro25);

					centro26 = getCenter([centro13, centro3]);
					passosLatLong.push(centro26);

					centro27 = getCenter([centro3, centro14]);
					passosLatLong.push(centro27);

					centro28 = getCenter([centro14, centro7]);
					passosLatLong.push(centro28);

					centro29 = getCenter([centro7, centro15]);
					passosLatLong.push(centro29);

					centro30 = getCenter([centro15, pontoB]);
					passosLatLong.push(centro30);
				  }

				  if (distancia > 300000) {
					console.log('AAAAAAAAAAAAAAAAAAAAAAAa');
					console.log(centro23.latitude,',', centro23.longitude);
				  }

				  passosLatLong.push(pontoB);

				  for (var k = 0; k < props.pontos.length; k++) {
					  let ponto = props.pontos[k];
					  
					  if (ponto.latitude && ponto.longitude) {
						  let distanciaOrigem = getDistance({ latitude: item.start_location.lat(), longitude: item.start_location.lng() }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) });
						  let distanciaDestino = getDistance({ latitude: item.end_location.lat(), longitude: item.end_location.lng() }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) });
						  let distanciaCentro1 = centro1 ? getDistance({ latitude: centro1.latitude, longitude: centro1.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro2 = centro2 ? getDistance({ latitude: centro2.latitude, longitude: centro2.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro3 = centro3 ? getDistance({ latitude: centro3.latitude, longitude: centro3.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro4 = centro4 ? getDistance({ latitude: centro4.latitude, longitude: centro4.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro5 = centro5 ? getDistance({ latitude: centro5.latitude, longitude: centro5.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro6 = centro6 ? getDistance({ latitude: centro6.latitude, longitude: centro6.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro7 = centro7 ? getDistance({ latitude: centro7.latitude, longitude: centro7.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro8 = centro8 ? getDistance({ latitude: centro8.latitude, longitude: centro8.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro9 = centro9 ? getDistance({ latitude: centro9.latitude, longitude: centro9.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro10 = centro10 ? getDistance({ latitude: centro10.latitude, longitude: centro10.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro11 = centro11 ? getDistance({ latitude: centro11.latitude, longitude: centro11.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro12 = centro12 ? getDistance({ latitude: centro12.latitude, longitude: centro12.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro13 = centro13 ? getDistance({ latitude: centro13.latitude, longitude: centro13.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro14 = centro14 ? getDistance({ latitude: centro14.latitude, longitude: centro14.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro15 = centro15 ? getDistance({ latitude: centro15.latitude, longitude: centro15.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  
						  let distanciaCentro16 = centro16 ? getDistance({ latitude: centro16.latitude, longitude: centro16.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro17 = centro17 ? getDistance({ latitude: centro17.latitude, longitude: centro17.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro18 = centro18 ? getDistance({ latitude: centro18.latitude, longitude: centro18.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro19 = centro19 ? getDistance({ latitude: centro19.latitude, longitude: centro19.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro20 = centro20 ? getDistance({ latitude: centro20.latitude, longitude: centro20.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro21 = centro21 ? getDistance({ latitude: centro21.latitude, longitude: centro21.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro22 = centro22 ? getDistance({ latitude: centro22.latitude, longitude: centro22.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro23 = centro23 ? getDistance({ latitude: centro23.latitude, longitude: centro23.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro24 = centro24 ? getDistance({ latitude: centro24.latitude, longitude: centro24.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro25 = centro25 ? getDistance({ latitude: centro25.latitude, longitude: centro25.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro26 = centro26 ? getDistance({ latitude: centro26.latitude, longitude: centro26.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro27 = centro27 ? getDistance({ latitude: centro27.latitude, longitude: centro27.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro28 = centro28 ? getDistance({ latitude: centro28.latitude, longitude: centro28.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro29 = centro29 ? getDistance({ latitude: centro29.latitude, longitude: centro29.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
						  let distanciaCentro30 = centro30 ? getDistance({ latitude: centro30.latitude, longitude: centro30.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;

						  if (distanciaOrigem < raio || distanciaDestino < raio 
								|| (distanciaCentro1 && distanciaCentro1 < raio)
								|| (distanciaCentro2 && distanciaCentro2 < raio)
								|| (distanciaCentro3 && distanciaCentro3 < raio)
								|| (distanciaCentro4 && distanciaCentro4 < raio)
								|| (distanciaCentro5 && distanciaCentro5 < raio)
								|| (distanciaCentro6 && distanciaCentro6 < raio)
								|| (distanciaCentro7 && distanciaCentro7 < raio)
								|| (distanciaCentro8 && distanciaCentro8 < raio)
								|| (distanciaCentro9 && distanciaCentro9 < raio)
								|| (distanciaCentro10 && distanciaCentro10 < raio)
								|| (distanciaCentro11 && distanciaCentro11 < raio)
								|| (distanciaCentro12 && distanciaCentro12 < raio)
								|| (distanciaCentro13 && distanciaCentro13 < raio)
								|| (distanciaCentro14 && distanciaCentro14 < raio)
								|| (distanciaCentro15 && distanciaCentro15 < raio)
								|| (distanciaCentro16 && distanciaCentro16 < raio)
								|| (distanciaCentro17 && distanciaCentro17 < raio)
								|| (distanciaCentro18 && distanciaCentro18 < raio)
								|| (distanciaCentro19 && distanciaCentro19 < raio)
								|| (distanciaCentro20 && distanciaCentro20 < raio)
								|| (distanciaCentro21 && distanciaCentro21 < raio)
								|| (distanciaCentro22 && distanciaCentro22 < raio)
								|| (distanciaCentro23 && distanciaCentro23 < raio)
								|| (distanciaCentro24 && distanciaCentro24 < raio)
								|| (distanciaCentro25 && distanciaCentro25 < raio)
								|| (distanciaCentro26 && distanciaCentro26 < raio)
								|| (distanciaCentro27 && distanciaCentro27 < raio)
								|| (distanciaCentro28 && distanciaCentro28 < raio)
								|| (distanciaCentro29 && distanciaCentro29 < raio)
								|| (distanciaCentro30 && distanciaCentro30 < raio)															
							) {
							if (!pontos.filter(item => item.descricao == ponto.descricao)[0]) {
							  pontos.push(ponto);
							}
						  }
					  }
				  }
				  */
			  }
		  }
		  // }		  

		  props.setRota({
			  rotaCarregada: result,
			  origin: { latitude: props.origem.latitude, longitude: props.origem.longitude },
			  destino: { latitude: props.destino.latitude, longitude: props.destino.longitude },
			  itens: (props.itens || []).map(item => ({ latitude: item.latitude, longitude: item.longitude })),
			  waypoints: waypoints,
			  link_google_maps: link,
			  distancia: result.routes[0].legs[0].distance,
			  passos_localizacao: !itens && props.rota ? props.rota.passos_localizacao : passosLatLong,
			  pontos: pontos,
			  overview_polyline: result.routes[0].overview_polyline
		  });

	   } else {
		   props.setRota(null);
	   }
   }

   if (props.rota && !props.rotaCarregada) {
	   calcularRota();
   }

   if (isLoaded) {
		return (
			<>
				<Row alignCenter style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
					<Grid cols='12 12 8 6'>
						<Button
							text='Montar Rota'
							type={'primary'}
							icon={'fa fa-chevron-left'}
							event={() => {
								calcularRota(props.itens || []);
							}} />
					</Grid>
				</Row>

				<Row>
					<LabelAndInput
						name='link'
						label='Link Google Maps' placeholder=''
						cols='12 12 12 12'
						value={props.rota ? props.rota.link_google_maps : null}
						onChange={() => {}} />
				</Row>

				{props.rotaCarregada ? (
					<GoogleMap
						mapContainerStyle={containerStyle}
						zoom={10}
						onLoad={map => {

						}}
						onClick={(e) => {

						}} >

						{props.origem ? (
							<Marker position={{ lat: parseFloat(props.origem.latitude), lng: parseFloat(props.origem.longitude) }}
							 	options={{
									label: {
										text: 'Origem',
										className: 'map-marker'
									}
								}}/>
						) : null}
						{(props.itens || props.rota.itens || []).map((item, i) => {
							return (
								<Marker key={i}
									position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }}
								 	options={{
										label: {
											text: item.descricao,
											className: 'map-marker'
										}
									}}/>
							);
						})}
						{props.destino ? (
							<Marker position={{ lat: parseFloat(props.destino.latitude), lng: parseFloat(props.destino.longitude) }}
							 	options={{
									label: {
										text: 'Destino',
										className: 'map-marker'
									}
								}}/>
						) : null}

						{props.rota && props.rota.pontos ? props.rota.pontos.map(item => {
							return (
								<Marker key={item.descricao}
									position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }}
								 	options={{
										label: {
											text: item.descricao,
											className: 'map-marker'
										}
									}}/>
							);
						}) : null}

						{props.rotaCarregada ? (
							<DirectionsRenderer
								directions={props.rotaCarregada}
								ref={ref => setDirectionsRef(ref)}
								onDirectionsChanged={async (e, a) => {

									if (directionsRef && directionsRef.state && directionsRef.state.directionsRenderer && directionsRef.state.directionsRenderer.directions
										&& directionsRef.state.directionsRenderer.directions && directionsRef.state.directionsRenderer.directions.geocoded_waypoints.length != props.rotaCarregada.geocoded_waypoints.length) {

										let waypoints = [];

										for (var i = 1; i < directionsRef.state.directionsRenderer.directions.geocoded_waypoints.length -1; i++) {
											let waypoint = directionsRef.state.directionsRenderer.directions.geocoded_waypoints[i];
											await geocodeByPlaceId(waypoint.place_id).then(results => {
												if (waypoints.length < 8) {
													waypoints.push({ latitude: results[0].geometry.location.lat(), longitude: results[0].geometry.location.lng() });
												} else {
													setError('Você não pode mais selecionar pontos.');
												}
											}).catch(error => console.error(error));
										}

										let link = `https://www.google.com/maps/dir/?api=1&origin=${props.origem.latitude},${props.origem.longitude}&destination=${props.destino.latitude},${props.destino.longitude}&waypoints=${waypoints.map(waypoint => `${waypoint.latitude},${waypoint.longitude}`).join('|')}&travelmode=driving`;

										let pontos = [];
										let passosLatLong = [];

										for (var i = 0; i < directionsRef.state.directionsRenderer.directions.routes[0].legs.length; i++) {
											let leg = directionsRef.state.directionsRenderer.directions.routes[0].legs[i];

											for (var j = 0; j < leg.steps.length; j++) {
												let item = leg.steps[j];

												const pontoA = { latitude: item.start_location.lat(), longitude: item.start_location.lng() };
												passosLatLong.push(pontoA);

												let subSteps = [];
												if (item.distance.value > 2000) {
													subSteps = subdividirSteps(item, 2000);

													for (let k = 0; k < subSteps.length; k++) {
														const subStep = subSteps[k];

														passosLatLong.push({ latitude: subStep.start_location.lat(), longitude: subStep.start_location.lng() });
													}
												}

												const pontoB = { latitude: item.end_location.lat(), longitude: item.end_location.lng() };
												passosLatLong.push(pontoB);


												for (var k = 0; k < props.pontos.length; k++) {
													let ponto = props.pontos[k];

													if (ponto.latitude && ponto.longitude) {
														let distanciaOrigem = getDistance({ latitude: item.start_location.lat(), longitude: item.start_location.lng() }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) });
														let distanciaDestino = getDistance({ latitude: item.end_location.lat(), longitude: item.end_location.lng() }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) });

														let dentroRaio = distanciaOrigem < raio || distanciaDestino < raio;

														// console.log('------------------------------------------------');
														// console.log(`${item.start_location.lat()}, ${item.start_location.lng()}`);


														if (!dentroRaio) {

															for (let l = 0; l < subSteps.length; l++) {
																const subStep = subSteps[l];


																// console.log(`${subStep.start_location.lat()}, ${subStep.start_location.lng()}`);

																if (!dentroRaio) {
																	let distanciaCentro = getDistance({ latitude: subStep.start_location.lat(), longitude: subStep.start_location.lng() }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) });

																	dentroRaio = distanciaCentro < raio;
																}
															}

														}

														// console.log(`${item.end_location.lat()}, ${item.end_location.lng()}`);

														if (dentroRaio) {
															if (!pontos.filter(item => item.descricao == ponto.descricao)[0]) {
																pontos.push(ponto);
															}
														}
													}
												}

												/*

												const pontoA = { latitude: item.start_location.lat(), longitude: item.start_location.lng() };
												const pontoB = { latitude: item.end_location.lat(), longitude: item.end_location.lng() };
												
												const distancia = getPreciseDistance({ lat: pontoA.latitude, lng: pontoA.longitude }, { lat: pontoB.latitude, lng: pontoB.longitude });
												//   const quantidadePontosIntermediarios = Math.floor(distancia / 3000) - 1;
																
												passosLatLong.push(pontoA);
												
												let centro1 = null;
												let centro2 = null;
												let centro3 = null;
												let centro4 = null;
												let centro5 = null;
												let centro6 = null;
												let centro7 = null;
												let centro8 = null;
												let centro9 = null;
												let centro10 = null;
												let centro11 = null;
												let centro12 = null;
												let centro13 = null;
												let centro14 = null;
												let centro15 = null;

												if (distancia > 10000) {
													centro1 = getCenter([pontoA, pontoB]);
													passosLatLong.push(centro1);
												}

												if (distancia > 20000) {
													centro2 = getCenter([pontoA, centro1]);
													passosLatLong.push(centro2);
													centro3 = getCenter([centro1, pontoB]);
													passosLatLong.push(centro3);
												}

												if (distancia > 40000) {
													centro4 = getCenter([pontoA, centro2]);
													passosLatLong.push(centro4);
													centro5 = getCenter([centro2, centro1]);
													passosLatLong.push(centro5);

													centro6 = getCenter([centro1, centro3]);
													passosLatLong.push(centro6);
													centro7 = getCenter([centro3, pontoB]);
													passosLatLong.push(centro7);
												}

												if (distancia > 100000) {
													centro8 = getCenter([pontoA, centro4]);
													passosLatLong.push(centro9);

													centro9 = getCenter([centro4, centro2]);
													passosLatLong.push(centro9);

													centro10 = getCenter([centro2, centro5]);
													passosLatLong.push(centro10);

													centro11 = getCenter([centro5, centro1]);
													passosLatLong.push(centro11);

													centro12 = getCenter([centro1, centro6]);
													passosLatLong.push(centro12);

													centro13 = getCenter([centro6, centro3]);
													passosLatLong.push(centro13);

													centro14 = getCenter([centro3, centro7]);
													passosLatLong.push(centro14);

													centro15 = getCenter([centro7, pontoB]);
													passosLatLong.push(centro15);
												}

												passosLatLong.push(pontoB);

												for (var k = 0; k < props.pontos.length; k++) {

													let ponto = props.pontos[k];
													if (ponto.latitude && ponto.longitude) {
														
														let distanciaOrigem = getDistance({ latitude: item.start_location.lat(), longitude: item.start_location.lng() }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) });
														let distanciaDestino = getDistance({ latitude: item.end_location.lat(), longitude: item.end_location.lng() }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) });
														let distanciaCentro1 = centro1 ? getDistance({ latitude: centro1.latitude, longitude: centro1.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro2 = centro2 ? getDistance({ latitude: centro2.latitude, longitude: centro2.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro3 = centro3 ? getDistance({ latitude: centro3.latitude, longitude: centro3.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro4 = centro4 ? getDistance({ latitude: centro4.latitude, longitude: centro4.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro5 = centro5 ? getDistance({ latitude: centro5.latitude, longitude: centro5.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro6 = centro6 ? getDistance({ latitude: centro6.latitude, longitude: centro6.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro7 = centro7 ? getDistance({ latitude: centro7.latitude, longitude: centro7.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro8 = centro8 ? getDistance({ latitude: centro8.latitude, longitude: centro8.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro9 = centro9 ? getDistance({ latitude: centro9.latitude, longitude: centro9.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro10 = centro10 ? getDistance({ latitude: centro10.latitude, longitude: centro10.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro11 = centro11 ? getDistance({ latitude: centro11.latitude, longitude: centro11.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro12 = centro12 ? getDistance({ latitude: centro12.latitude, longitude: centro12.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro13 = centro13 ? getDistance({ latitude: centro13.latitude, longitude: centro13.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro14 = centro14 ? getDistance({ latitude: centro14.latitude, longitude: centro14.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														let distanciaCentro15 = centro15 ? getDistance({ latitude: centro15.latitude, longitude: centro15.longitude }, { latitude: parseFloat(ponto.latitude), longitude: parseFloat(ponto.longitude) }) : null;
														if (distanciaOrigem < raio || distanciaDestino < raio 
																|| (distanciaCentro1 && distanciaCentro1 < raio)
																|| (distanciaCentro2 && distanciaCentro2 < raio)
																|| (distanciaCentro3 && distanciaCentro3 < raio)
																|| (distanciaCentro4 && distanciaCentro4 < raio)
																|| (distanciaCentro5 && distanciaCentro5 < raio)
																|| (distanciaCentro6 && distanciaCentro6 < raio)
																|| (distanciaCentro7 && distanciaCentro7 < raio)
																|| (distanciaCentro8 && distanciaCentro8 < raio)
																|| (distanciaCentro9 && distanciaCentro9 < raio)
																|| (distanciaCentro10 && distanciaCentro10 < raio)
																|| (distanciaCentro11 && distanciaCentro11 < raio)
																|| (distanciaCentro12 && distanciaCentro12 < raio)
																|| (distanciaCentro13 && distanciaCentro13 < raio)
																|| (distanciaCentro14 && distanciaCentro14 < raio)
																|| (distanciaCentro15 && distanciaCentro15 < raio)) {
															if (!pontos.filter(item => item.descricao == ponto.descricao)[0]) {
																pontos.push(ponto);
															}
														}
													}
												}
												*/
											}
										}

										props.setRota({
											rotaCarregada: directionsRef.state.directionsRenderer.directions,
											origin: { latitude: props.origem.latitude, longitude: props.origem.longitude },
											destino: { latitude: props.destino.latitude, longitude: props.destino.longitude },
											waypoints: waypoints,
											link_google_maps: link,
											distancia: directionsRef.state.directionsRenderer.directions.routes[0].legs[0].distance,
											passos_localizacao: passosLatLong,
											pontos: pontos,
											overview_polyline: directionsRef.state.directionsRenderer.directions.routes[0].overview_polyline
										});
									}
								}}
								onLoad={(e, a) => {

								}}
								options={{
									suppressMarkers: true,
									draggable: true
								}} />
						) : null}

					</GoogleMap>
				) : null}
			</>
		);
	} else {
		return null;
	}
}
