import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from '../common/widget/valueBox'
import Row from '../common/layout/row'
import InfoBox from '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';

import imagemUser from '../assets/images/user.jpg';

import ViagemForm from '../viagem/viagemForm';
import ManutencaoForm from '../manutencao/manutencaoForm';

import { } from './dashboardActions';
import {
	setModoTela as setModoTelaViagem, initForm as initFormViagem, salvar as salvarViagem, excluir as excluirViagem,
	getLista as getListaViagem,
	getListaEstado,
	getListaCidade,
	getListaViagemStatus,
	getListaViagemLancamento,
	getListaLocal,
	getListaVeiculo,
	excluirVeiculo,
	getListaMotorista,
	getListaTransportadora,
	getListaProduto,
	getListaUnidadeMedida,
	getListaProximaCarga,
	getListaPosto,
	getListaRota
} from '../viagem/viagemActions';

import {
	initForm as initFormManutencao, salvar as salvarManutencao, excluir as excluirManutencao
} from '../manutencao/manutencaoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';

class Dashboard extends Component {

	state = {
		linhaSelecionada: null,
		terceiro: window.location.href.includes('terceiro'),
		pesquisar: ''
	}

	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.props.getListaViagem();
		this.props.getListaEstado();
		this.props.getListaCidade();
		this.props.getListaViagemStatus();
		this.props.getListaViagemLancamento();
		this.props.getListaLocal();
		this.props.getListaVeiculo();
		this.props.getListaMotorista();
		this.props.getListaTransportadora();
		this.props.getListaProduto();
		this.props.getListaUnidadeMedida();
		this.props.getListaProximaCarga();
		this.props.getListaPosto();
		this.props.getListaRota();

		setInterval(() => {
			this.props.getListaViagem();
		}, 100000);



		this.setState({
			...this.state,
			terceiro: window.location.href.includes('terceiro'),
			relogio: `${DateFormat.getDiaSemana(new Date())}, ${DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())}`

		})

	}

	componentWillUpdate() {
		if (window.location.href.includes('terceiro') != this.state.terceiro) {
			this.setState({
				...this.state,
				terceiro: window.location.href.includes('terceiro')
			});
		}
	}

	render() {

		let dataDescargaAnterior = null;

		let idMotorista = this.props.sessao && this.props.sessao.id_motorista ? this.props.sessao.id_motorista : null;

		let acessoTV = this.props.sessao && (this.props.sessao.id == 43); //TV SALA LOGÍSTICA

		return (
			<Content>
				
				<Aguardando aguardando={this.props.aguardando} />

				{this.props.modoTelaViagem == 'cadastro' ? (
					<ViagemForm onSubmit={this.props.salvarViagem} />
				) : (this.props.modoTelaViagem == 'exclusao' ? (
					<ViagemForm excluir onSubmit={this.props.excluirViagem} />
				) : (this.props.modoTelaViagem == 'manutencaoCadastro' ? (
					<ManutencaoForm onSubmit={this.props.salvarManutencao} />
				) : (
					<ContentCard style={{ height: window.innerHeight - 100 }}>
						<ContentCardBody style={{ height: window.innerHeight }}>

							{!acessoTV ? (
								<input className='form-control'
									placeholder='Pesquisar'
									type='text'
									value={this.state.pesquisar}
									onChange={e => {
										this.setState({ ...this.state, pesquisar: e.target.value });
									}} />
							) : null}

							<Table responsive striped style={{ maxHeight: '100%' }}>
								<THead>
									<Tr>
										<Th>Motorista</Th>
										<Th alignCenter>Placa</Th>
										<Th alignCenter>Dias</Th>
										<Th alignCenter>Status</Th>
										<Th alignCenter>Doc.</Th>
										<Th alignCenter>Abast.</Th>
										<Th alignLeft>Origem Carga</Th>
										<Th alignCenter>Data Carga</Th>
										<Th alignLeft>Destino Carga</Th>
										<Th alignCenter>Data Descarga</Th>
										<Th alignLeft>Próxima Carga</Th>
										<Th alignCenter>Próxima Agendada</Th>
										{/*<Th alignCenter>Manutenção</Th>*/}
										<Th alignLeft>Transportadora</Th>
										{!acessoTV &&
										<Th alignCenter width={100}></Th>}
									</Tr>
								</THead>
								<TBody>

									{this.props.listaViagem.filter(item =>

										!idMotorista || idMotorista == item.id_motorista
									).filter(item => {
										if (this.state.pesquisar
											&& !(`${item.nome_motorista} ${item.placa_veiculo} ${item.dias_viagem} ${item.deslocamento_vazio ? 'D. Vazio' : item.nome_viagem_status}
													${item.documentacao ? 'Sim' : 'Não'} ${item.rota_abastecimento ? 'Sim' : 'Não'}
													${item.nome_cidade_carga} ${item.sigla_estado_carga} ${item.nome_usuario}
													${item.datahora_carga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga, false) : ''}
													${item.nome_cidade_descarga} ${item.sigla_estado_descarga} ${item.nome_transportadora} 
													${item.datahora_descarga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga, false) : ''}
												`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
											return false;
										}
										return true;

									}).filter(item => item.veiculo_terceiro == this.state.terceiro).map((item, i) => {

										let dataDescarga = item.datahora_descarga ? item.datahora_descarga.split(' ')[0] : '';
										let dataDiferente = false;
										if (dataDescargaAnterior != null && dataDescarga != dataDescargaAnterior) {
											dataDiferente = true;
										}
										dataDescargaAnterior = dataDescarga;

										const title =
													`Status: ${item.deslocamento_vazio ? 'D. Vazio' : (!item.km_inicial ? 'Agendado' : (!item.km_final ? 'Trânsito' : 'Finalizado'))} \n` +
													`Agendamento: ${item.datahora_agendamento ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_agendamento, false) : 'Não informado'} \n \n` +
													`Coleta / Entrega \n` +
													`${(item.itens || []).map((coletaEntrega, i) => (
														`${i+1}: ${coletaEntrega.nome_cidade_carga || ''} - ${coletaEntrega.sigla_estado_carga || ''} => ${coletaEntrega.nome_cidade_descarga || ''} - ${coletaEntrega.sigla_estado_descarga || ''}`
													)).join('\n')}` +
													`\n \n` +
													`Informações \n` +
													`Transportadora: ${item.nome_transportadora || ''} \n` +
													`${(item.itens || []).map((coletaEntrega, i) => (
														`Produto ${i+1}: ${coletaEntrega.nome_produto || 'não informado'} \n` +
														`Quantidade: ${FormatUtils.formatarValorTelaDecimal(coletaEntrega.quantidade || 0, 2)} ${coletaEntrega.nome_unidade_medida}`
													)).join('\n \n')}` +
													`\n` +
													`Agendado Por: ${item.responsavel_agendamento || ''} \n` +
													`Observação: ${item.observacao || ''} \n` ;

										return (
											<Tr key={i}
												style={{ borderTop: dataDiferente ? '2px solid rgb(128, 128, 128)' : null }}
												onClick={() => this.setState({ ...this.state, linhaSelecionada: i })}
												onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: i })} >
												<Td title={title}>{item.nome_motorista}</Td>
												<Td alignCenter title={title}>{item.placa_veiculo}</Td>
												<Td alignCenter title={title}>{item.dias_viagem}</Td>
												<Td alignCenter title={title} style={{ fontWeight: item.cor_viagem_status ? 'bold' : null, color: item.cor_viagem_status }}>{item.deslocamento_vazio ? 'D. Vazio' : item.nome_viagem_status}</Td>
												<Td alignCenter title={title} style={{ fontWeight: 'bold', color: item.documentacao ? '#43a047' : '#d32f2f' }}>{item.documentacao ? 'Sim' : 'Não'}</Td>
												<Td alignCenter title={title} style={{ fontWeight: 'bold', color: item.rota_abastecimento ? '#43a047' : '#d32f2f' }}>{item.rota_abastecimento ? 'Sim' : 'Não'}</Td>
												<Td alignLeft title={title}>{item.nome_cidade_carga} - {item.sigla_estado_carga}</Td>
												<Td alignCenter title={title}>{item.datahora_carga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga, false) : ''}</Td>
												<Td alignLeft title={title}>{item.nome_cidade_descarga} - {item.sigla_estado_descarga}</Td>
												<Td alignCenter title={title}>{item.datahora_descarga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga, false) : ''}</Td>
												<Td alignLeft title={title}>{item.nome_usuario}</Td>
												<Td alignCenter
													title={item.viagem_agendada ? (
														`Status: ${!item.viagem_agendada.km_inicial ? 'Agendado' : (!item.viagem_agendada.km_final ? 'Trânsito' : 'Finalizado')} \n` +
														`Agendamento: ${item.viagem_agendada.datahora_agendamento ? DateFormat.formatarDataHoraSqlParaTela(item.viagem_agendada.datahora_agendamento, false) : 'Não informado'} \n \n` +
														`Coleta / Entrega \n` +
														`${(item.viagem_agendada.itens || []).map((coletaEntrega, i) => (
															`${i+1}: ${coletaEntrega.nome_cidade_carga || ''} - ${coletaEntrega.sigla_estado_carga || ''} => ${coletaEntrega.nome_cidade_descarga || ''} - ${coletaEntrega.sigla_estado_descarga || ''}`
														)).join('\n')}` +
														`\n \n` +
														`Informações \n` +
														`Transportadora: ${item.viagem_agendada.nome_transportadora || ''} \n` +
														`${(item.viagem_agendada.itens || []).map((coletaEntrega, i) => (
															`Produto ${i+1}: ${coletaEntrega.nome_produto || 'não informado'} \n` +
															`Quantidade: ${FormatUtils.formatarValorTelaDecimal(coletaEntrega.quantidade || 0, 2)} ${coletaEntrega.nome_unidade_medida}`
														)).join('\n \n')}` +
														`\n` +
														`Agendado Por: ${item.viagem_agendada.responsavel_agendamento || ''} \n` +
														`Observação: ${item.viagem_agendada.observacao || ''} \n` 
													) : (
														''
													)} >
													{acessoTV ? (
														item.viagem_agendada ? DateFormat.formatarDataHoraSqlParaTela(item.viagem_agendada.datahora_agendamento, false) : ''
													) : (item.viagem_agendada && !idMotorista ? (
														<Button
															type={'primary'}
															icon={'fas fa-pencil-alt'}
															visible={true}
															text={item.viagem_agendada.datahora_agendamento ? DateFormat.formatarDataHoraSqlParaTela(item.viagem_agendada.datahora_agendamento, false) : 'Sem Horário'}
															small
															disabled={this.props.permissoes.acesso_logistica != 2}
															event={() => {
																let registro = {
																	...item.viagem_agendada,
																	datahora_agendamento: item.viagem_agendada.datahora_agendamento ? DateFormat.formatarDataHoraSqlParaTela(item.viagem_agendada.datahora_agendamento) : null,
																	datahora_carga: item.viagem_agendada.datahora_carga ? DateFormat.formatarDataHoraSqlParaTela(item.viagem_agendada.datahora_carga) : null,
																	datahora_descarga: item.viagem_agendada.datahora_descarga ? DateFormat.formatarDataHoraSqlParaTela(item.viagem_agendada.datahora_descarga) : null,
																	quantidade: String(item.viagem_agendada.quantidade || 0).replace(".", ","),
																	preco_unitario: String(item.viagem_agendada.preco_unitario || 0).replace(".", ","),
																	valor_tarifa: String(item.viagem_agendada.valor_tarifa || 0).replace(".", ","),
																	percentual_adiantamento: String(item.viagem_agendada.percentual_adiantamento || 0).replace(".", ","),
																	percentual_saldo: String(item.viagem_agendada.percentual_saldo || 0).replace(".", ","),
																	valor_adiantamento: String(item.viagem_agendada.valor_adiantamento || 0).replace(".", ","),
																	valor_saldo: String(item.viagem_agendada.valor_saldo || 0).replace(".", ","),
																	valor_pedagio: String(item.viagem_agendada.valor_pedagio || 0).replace(".", ","),
																	valor_descarga: String(item.viagem_agendada.valor_descarga || 0).replace(".", ","),
																	porcentagem_comissao_moto: String(item.viagem_agendada.porcentagem_comissao_moto || 0).replace(".", ","),
																	qtde_km: String(item.viagem_agendada.qtde_km || 0).replace(".", ","),
																	media: String(item.viagem_agendada.media || 0).replace(".", ","),
																	preco_combustivel: String(item.viagem_agendada.preco_combustivel || 0).replace(".", ","),
																	seguro_carga: String(item.viagem_agendada.seguro_carga || 0).replace(".", ","),
																	outros_custos: String(item.viagem_agendada.outros_custos || 0).replace(".", ","),
																	deslocamento_origem_destino: item.viagem_agendada.deslocamento_origem_destino,
																	deslocamento_qtde_km: String(item.viagem_agendada.deslocamento_qtde_km || 0).replace(".", ","),
																	deslocamento_media: String(item.viagem_agendada.deslocamento_media || 0).replace(".", ","),
																	deslocamento_preco_combustivel: String(item.viagem_agendada.deslocamento_preco_combustivel || 0).replace(".", ","),
																	deslocamento_pedagio: String(item.viagem_agendada.deslocamento_pedagio || 0).replace(".", ","),
																	carregado_pedagio: String(item.viagem_agendada.carregado_pedagio || 0).replace(".", ","),
																	valor_tarifa_motorista: String(item.viagem_agendada.valor_tarifa_motorista || 0).replace(".", ","),
																	diaria_empresa: String(item.viagem_agendada.diaria_empresa || 0).replace(".", ","),
																	diaria_motorista: String(item.viagem_agendada.diaria_motorista || 0).replace(".", ","),
																	diaria_total: (parseFloat(item.viagem_agendada.diaria_empresa || 0) + parseFloat(item.viagem_agendada.diaria_motorista || 0)).toFixed(2).replace(".", ","),
																	outros_custos_2: String(item.viagem_agendada.outros_custos_2 || 0).replace(".", ","),
																	diaria_total_2: String(item.viagem_agendada.diaria_total_2 || 0).replace(".", ","),
																	outros_custos_3: String(item.viagem_agendada.outros_custos_3 || 0).replace(".", ","),
																	diaria_total_3: String(item.viagem_agendada.diaria_total_3 || 0).replace(".", ","),
																	outros_custos_4: String(item.viagem_agendada.outros_custos_4 || 0).replace(".", ","),
																	diaria_total_4: String(item.viagem_agendada.diaria_total_4 || 0).replace(".", ","),
																	outros_custos_5: String(item.viagem_agendada.outros_custos_5 || 0).replace(".", ","),
																	diaria_total_5: String(item.viagem_agendada.diaria_total_5 || 0).replace(".", ",")
																}

																this.props.setModoTelaViagem('cadastro', registro);
																this.props.initFormViagem(registro);
															}} />
											
													) : (!idMotorista ? (
														<Button
															text='Agendar'
															type={'success'}
															icon={'fa fa-plus'}
															small
															disabled={this.props.permissoes.acesso_logistica != 2}
															event={() => {
																this.props.setModoTelaViagem('cadastro', {});
																this.props.initFormViagem({
																	responsavel_agendamento: this.props.sessao.nome,
																	id_veiculo: item.id_veiculo_controle,
																	id_motorista: item.id_motorista_controle,
																	terceiro: this.state.terceiro
																});
															}} />
													) : null))}
												</Td>

												{/*<Td alignCenter>{item.manutencao_aberta ? (
													<Button
														type={'primary'}
														icon={'fas fa-pencil-alt'}
														visible={true}
														text={item.manutencao_aberta.datahora_inicio ? 'Executando' : (item.manutencao_aberta.datahora_agendamento ? DateFormat.formatarDataHoraSqlParaTela(item.manutencao_aberta.datahora_agendamento, false) : 'Sem Horário')}
														small
														event={() => {
															let registro = {
																...item.manutencao_aberta,
																datahora_agendamento: item.manutencao_aberta.datahora_agendamento ? DateFormat.formatarDataHoraSqlParaTela(item.manutencao_aberta.datahora_agendamento) : null,
																datahora_previsao_termino: item.manutencao_aberta.datahora_previsao_termino ? DateFormat.formatarDataHoraSqlParaTela(item.manutencao_aberta.datahora_previsao_termino) : null,
																datahora_inicio: item.manutencao_aberta.datahora_inicio ? DateFormat.formatarDataHoraSqlParaTela(item.manutencao_aberta.datahora_inicio) : null,
																datahora_fim: item.manutencao_aberta.datahora_fim ? DateFormat.formatarDataHoraSqlParaTela(item.manutencao_aberta.datahora_fim) : null
															}

															this.props.setModoTelaViagem('manutencaoCadastro', registro);
															this.props.initFormManutencao(registro);
														}} />
												) : (
													<Button
														text='Agendar'
														type={'success'}
														icon={'fa fa-plus'}
														small
														event={() => {
															this.props.setModoTelaViagem('manutencaoCadastro', {});
															this.props.initFormManutencao({
																id_veiculo: item.id_veiculo_controle
															});
														}} />
												)}</Td>*/}
												<Td alignLeft title={title}>{item.nome_transportadora}</Td>
												{acessoTV ? 
													null 
												: (item.nome_viagem_status == 'Ocioso' ? (
													<Td alignRight>
														{!idMotorista &&
															<ButtonTable
																type={'danger'}
																icon={'fas fa-trash-alt'}
																visible={this.state.linhaSelecionada == i}
																disabled={this.props.permissoes.acesso_logistica != 2}
																event={() => {
																	this.props.excluirVeiculo({ id: item.id_veiculo_controle });
																}} />}
													</Td>
												) : (
													<Td alignRight>
														<ButtonTable
															type={'warning'}
															icon={'fas fa-pencil-alt'}
															visible={this.state.linhaSelecionada == i}
															disabled={this.props.permissoes.acesso_logistica != 2}
															event={() => {
																let registro = {
																	...item,
																	datahora_agendamento: item.datahora_agendamento ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_agendamento) : null,
																	datahora_carga: item.datahora_carga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga) : null,
																	datahora_descarga: item.datahora_descarga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga) : null,
																	quantidade: String(item.quantidade || 0).replace(".", ","),
																	preco_unitario: String(item.preco_unitario || 0).replace(".", ","),
																	valor_tarifa: String(item.valor_tarifa || 0).replace(".", ","),
																	percentual_adiantamento: String(item.percentual_adiantamento || 0).replace(".", ","),
																	percentual_saldo: String(item.percentual_saldo || 0).replace(".", ","),
																	valor_adiantamento: String(item.valor_adiantamento || 0).replace(".", ","),
																	valor_saldo: String(item.valor_saldo || 0).replace(".", ","),
																	valor_pedagio: String(item.valor_pedagio || 0).replace(".", ","),
																	valor_descarga: String(item.valor_descarga || 0).replace(".", ","),
																	porcentagem_comissao_moto: String(item.porcentagem_comissao_moto || 0).replace(".", ","),
																	qtde_km: String(item.qtde_km || 0).replace(".", ","),
																	media: String(item.media || 0).replace(".", ","),
																	preco_combustivel: String(item.preco_combustivel || 0).replace(".", ","),
																	seguro_carga: String(item.seguro_carga || 0).replace(".", ","),
																	outros_custos: String(item.outros_custos || 0).replace(".", ","),
																	deslocamento_origem_destino: item.deslocamento_origem_destino,
																	deslocamento_qtde_km: String(item.deslocamento_qtde_km || 0).replace(".", ","),
																	deslocamento_media: String(item.deslocamento_media || 0).replace(".", ","),
																	deslocamento_preco_combustivel: String(item.deslocamento_preco_combustivel || 0).replace(".", ","),
																	deslocamento_pedagio: String(item.deslocamento_pedagio || 0).replace(".", ","),
																	carregado_pedagio: String(item.carregado_pedagio || 0).replace(".", ","),
																	valor_tarifa_motorista: String(item.valor_tarifa_motorista || 0).replace(".", ","),
																	diaria_empresa: String(item.diaria_empresa || 0).replace(".", ","),
																	diaria_motorista: String(item.diaria_motorista || 0).replace(".", ","),
																	diaria_total: (parseFloat(item.diaria_empresa || 0) + parseFloat(item.diaria_motorista || 0)).toFixed(2).replace(".", ","),
																	outros_custos_2: String(item.outros_custos_2 || 0).replace(".", ","),
																	diaria_total_2: String(item.diaria_total_2 || 0).replace(".", ","),
																	outros_custos_3: String(item.outros_custos_3 || 0).replace(".", ","),
																	diaria_total_3: String(item.diaria_total_3 || 0).replace(".", ","),
																	outros_custos_4: String(item.outros_custos_4 || 0).replace(".", ","),
																	diaria_total_4: String(item.diaria_total_4 || 0).replace(".", ","),
																	outros_custos_5: String(item.outros_custos_5 || 0).replace(".", ","),
																	diaria_total_5: String(item.diaria_total_5 || 0).replace(".", ",")
																}

																this.props.setModoTelaViagem('cadastro', registro);
																this.props.initFormViagem(registro);
															}} />

														{!idMotorista &&
															<ButtonTable
																type={'danger'}
																icon={'fas fa-trash-alt'}
																visible={this.state.linhaSelecionada == i}
																disabled={this.props.permissoes.acesso_logistica != 2}
																event={() => {
																	let registro = {
																		...item,
																		datahora_agendamento: item.datahora_agendamento ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_agendamento) : null,
																		datahora_carga: item.datahora_carga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga) : null,
																		datahora_descarga: item.datahora_descarga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga) : null,
																		quantidade: String(item.quantidade || 0).replace(".", ","),
																		preco_unitario: String(item.preco_unitario || 0).replace(".", ","),
																		valor_tarifa: String(item.valor_tarifa || 0).replace(".", ","),
																		percentual_adiantamento: String(item.percentual_adiantamento || 0).replace(".", ","),
																		percentual_saldo: String(item.percentual_saldo || 0).replace(".", ","),
																		valor_adiantamento: String(item.valor_adiantamento || 0).replace(".", ","),
																		valor_saldo: String(item.valor_saldo || 0).replace(".", ","),
																		valor_pedagio: String(item.valor_pedagio || 0).replace(".", ","),
																		valor_descarga: String(item.valor_descarga || 0).replace(".", ","),
																		porcentagem_comissao_moto: String(item.porcentagem_comissao_moto || 0).replace(".", ","),
																		qtde_km: String(item.qtde_km || 0).replace(".", ","),
																		media: String(item.media || 0).replace(".", ","),
																		preco_combustivel: String(item.preco_combustivel || 0).replace(".", ","),
																		seguro_carga: String(item.seguro_carga || 0).replace(".", ","),
																		outros_custos: String(item.outros_custos || 0).replace(".", ","),
																		deslocamento_origem_destino: item.deslocamento_origem_destino,
																		deslocamento_qtde_km: String(item.deslocamento_qtde_km || 0).replace(".", ","),
																		deslocamento_media: String(item.deslocamento_media || 0).replace(".", ","),
																		deslocamento_preco_combustivel: String(item.deslocamento_preco_combustivel || 0).replace(".", ","),
																		deslocamento_pedagio: String(item.deslocamento_pedagio || 0).replace(".", ","),
																		carregado_pedagio: String(item.carregado_pedagio || 0).replace(".", ","),
																		valor_tarifa_motorista: String(item.valor_tarifa_motorista || 0).replace(".", ","),
																		diaria_empresa: String(item.diaria_empresa || 0).replace(".", ","),
																		diaria_motorista: String(item.diaria_motorista || 0).replace(".", ","),
																		diaria_total: (parseFloat(item.diaria_empresa || 0) + parseFloat(item.diaria_motorista || 0)).toFixed(2).replace(".", ","),
																		outros_custos_2: String(item.outros_custos_2 || 0).replace(".", ","),
																		diaria_total_2: String(item.diaria_total_2 || 0).replace(".", ","),
																		outros_custos_3: String(item.outros_custos_3 || 0).replace(".", ","),
																		diaria_total_3: String(item.diaria_total_3 || 0).replace(".", ","),
																		outros_custos_4: String(item.outros_custos_4 || 0).replace(".", ","),
																		diaria_total_4: String(item.diaria_total_4 || 0).replace(".", ","),
																		outros_custos_5: String(item.outros_custos_5 || 0).replace(".", ","),
																		diaria_total_5: String(item.diaria_total_5 || 0).replace(".", ",")
																	}

																	this.props.setModoTelaViagem('exclusao', registro);
																	this.props.initFormViagem(registro);
																}} />}
													</Td>
												))}
											</Tr>
										)
									})}
								</TBody>
							</Table>
						</ContentCardBody>
						{this.props.permissoes.acesso_logistica == 2 && !idMotorista ? (
							<a id='back-to-top'
								class='btn btn-success back-to-top'
								role='button' aria-label='Scroll to top'
								style={{ borderRadius: '50%', width: 60, height: 60, paddingTop: 16, paddingLeft: 11 }}
								onClick={() => {
									this.props.setModoTelaViagem('cadastro', {});
									this.props.initFormViagem({
										responsavel_agendamento: this.props.sessao.nome,
										terceiro: this.state.terceiro
									});
								}}>
								<i class='fas fa-plus' style={{ fontSize: 24, padding: 0, margin: 0, color: '#fff' }}></i>
							</a>
						) : null}
					</ContentCard>
				)))}
			</Content>
		)
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.viagem.aguardando,
	listaViagem: state.viagem.lista,
	modoTelaViagem: state.viagem.modoTela,
	permissoes: state.usuario.permissoes
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getListaViagem, setModoTelaViagem, initFormViagem, salvarViagem, excluirViagem,
	getListaEstado,
	getListaCidade,
	getListaViagemStatus,
	getListaViagemLancamento,
	getListaLocal,
	getListaVeiculo,
	excluirVeiculo,
	getListaMotorista,
	getListaTransportadora,
	getListaProduto,
	getListaUnidadeMedida,
	getListaProximaCarga,
	getListaPosto,
	getListaRota,
	initFormManutencao, salvarManutencao, excluirManutencao
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
